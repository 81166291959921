import React, { useEffect, useState } from "react";
import Col from "../../../../components/codeebo-tools/grid/col";
import CdGrid from "../../../../components/codeebo-tools/grid/grid";
import Row from "../../../../components/codeebo-tools/grid/row";
import FullWidthFrame from "../../../../components/frames/fullwidth-frame/fullwidth-frame";
import "./CategorySection.scss";
import { useAppSelector } from "../../../../store";
import { selectHomeData } from "../../homeDataSlice";
import LinkButton from "../../../../components/LinkButtonsRepeater/LinkButton";

interface CategorySectionProps {}

const CategorySection: React.FC<CategorySectionProps> = (props) => {
  const homeData = useAppSelector(selectHomeData);
  const [data, set_data] = useState<any>(null);

  useEffect(() => {
    var tempData = homeData?.attributes?.EventsSpaRestaurant_Section;
    if (tempData) {
      set_data(tempData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [homeData]);

  return (
    <FullWidthFrame py={0} px={0}>
      <section className="CategorySection">
        <CdGrid>
          <Row align="center">
            {data?.map((x: any, index: number) => {
              return (
                <Col key={index} size={[4, 4, 4, 12]} className="img-block" style={{ backgroundColor: "#746143", backgroundImage: `url(${x.Picture?.data?.attributes?.url})` }}>
                  <LinkButton x={x} />
                </Col>
              );
            })}
          </Row>
        </CdGrid>
      </section>
    </FullWidthFrame>
  );
};

export default CategorySection;
