import React from "react";
import "./ListItem.scss";

interface ListItemProps {
  title: string;
  description?: string;
  actions?: Array<React.ReactNode>;
}

const ListItem: React.FC<ListItemProps> = (props) => {
  const { title, description, actions } = props;

  return (
    <li className="ListItem">
      <div className="content">
        <p className="item-title">
          <b>{title}</b>
        </p>
        {description ? <p className="montserrat">{description}</p> : null}
      </div>
      <div className="actions">
        {actions && actions.length > 0 ? (
          <p className="montserrat">
            <b>{actions[0]}</b>
          </p>
        ) : null}
        {actions && actions.length > 1 ? (
          <p className="montserrat">
            <b>
              <u>{actions[1]}</u>
            </b>
          </p>
        ) : null}
      </div>
    </li>
  );
};

export default ListItem;
