import React from "react";
import Container from "../../../../components/codeebo-tools/container/container";
import BisquitFrame from "../../../../components/frames/bisquit-frame/bisquit-frame";
import "./SkladnikiSection.scss";
import CdGrid from "../../../../components/codeebo-tools/grid/grid";
import Col from "../../../../components/codeebo-tools/grid/col";
import Row from "../../../../components/codeebo-tools/grid/row";
import TextBlock from "../../../../components/text-block/text-block";
import ExampleWhiteAImg from "./../../../../assets/example-white-a.png";

interface SkladnikiSectionProps {}

const SkladnikiSection: React.FC<SkladnikiSectionProps> = (props) => {
  return (
    <>
      <BisquitFrame padding={128}>
        <section className="SkladnikiSection">
          <Container>
            <CdGrid>
              <Row>
                <Col size={[5, 6, 6, 12]} offset={[1, 0, 0, 0]}>
                  <TextBlock>
                    <h5 className="pretitle">Spróbuj</h5>
                    <h2 className="title">Nasze składniki</h2>
                  </TextBlock>
                </Col>
              </Row>
              <Row className="content">
                <Col size={[4, 6, 12, 12]} offset={[1, 0]}>
                  <img className="offset-top" src={ExampleWhiteAImg} alt="example img" />
                  <TextBlock className="mt-48">
                    <p>Nam massa arcu, euismod quis dapibus eu, posuere a ipsum. Donec ullamcorper enim orci, ac venenatis lacus maximus consectetur. </p>
                  </TextBlock>
                </Col>
                <Col size={[4, 6, 12, 12]} offset={[1, 0]} className="mt-64">
                  <img src={ExampleWhiteAImg} alt="example img" />
                  <TextBlock className="mt-48">
                    <p>Nam massa arcu, euismod quis dapibus eu, posuere a ipsum.</p>
                  </TextBlock>
                </Col>
              </Row>
            </CdGrid>
          </Container>
        </section>
      </BisquitFrame>
    </>
  );
};

export default SkladnikiSection;
