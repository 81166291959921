import Button from "../button/button";
import t from "../lang-switch/t";
import "./sticky-booking-btn.scss";

interface Props {
  className?: string;
}

const StickyBookingBtn: React.FC<Props> = (props: Props) => {
  const { className = "" } = props;

  return (
    <div className={`sticky-booking-btn ${className}`}>
      <Button>{t("REZERWUJ", "BOOK")}</Button>
    </div>
  );
};

export default StickyBookingBtn;
