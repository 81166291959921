import MapSection from "../../sections/MapSection/MapSection";
import "./restauracja.scss";
import HeroSection from "./sections/HeroSection/HeroSection";
import MenuSection from "./sections/MenuSection/MenuSection";
import SkladnikiSection from "./sections/SkladnikiSection/SkladnikiSection";
import SzefSection from "./sections/SzefSection/SzefSection";

const RestauracjaPage: React.FC = () => {
  return (
    <div className="restauracja-page">
      <HeroSection />
      <MenuSection />
      <SzefSection />
      <SkladnikiSection />
      <MapSection />
    </div>
  );
};

export default RestauracjaPage;
