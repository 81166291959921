import React from "react";
import Container from "../../../../components/codeebo-tools/container/container";
import Col from "../../../../components/codeebo-tools/grid/col";
import Row from "../../../../components/codeebo-tools/grid/row";
import BisquitFrame from "../../../../components/frames/bisquit-frame/bisquit-frame";
import TextBlock from "../../../../components/text-block/text-block";
import "./ApartamentySection.scss";
import CdGrid from "../../../../components/codeebo-tools/grid/grid";
import ListItem from "../../../../components/ListItem/ListItem";

interface ApartamentySectionProps {}

const ApartamentySection: React.FC<ApartamentySectionProps> = (props) => {
  return (
    <>
      <BisquitFrame padding={96} className="HotelFrame">
        <section className="ApartamentySection">
          <Container>
            <CdGrid>
              <Row align="center">
                <Col size={[4, 5, 12, 12]} offset={[1, 0, 0, 0]}>
                  <TextBlock>
                    <h5 className="pretitle">Od 390 zł</h5>
                    <h2 className="title">
                      Apartamenty
                      <br /> Hotelu Szymbark
                    </h2>
                  </TextBlock>
                </Col>
                <Col size={[5, 6, 12, 12]} offset={[1, 1, 0, 0]}>
                  <TextBlock>
                    <p>Na każdym z poziomów znajdują się również apartamenty, których jest w Hotelu siedem. Każdy nazwany na cześć znamienitych Gości, którzy się w nich zatrzymali. </p>
                  </TextBlock>
                </Col>
              </Row>
              <Row className="items-list">
                <Col size={[10, 12, 12, 12]} offset={[1, 0, 0, 0]}>
                  <TextBlock>
                    <ul>
                      <ListItem title="Apartament Pana Leszka Możdżera" description="jako częsty gość grywa na jednej z atrakcji Hotelu, największym fortepianie świata." actions={[<>Zobacz&nbsp;galerię</>, <>Rezerwuj</>]} />
                      <ListItem title="Apartament Pana Lecha Wałęsy" description="były prezydent RP gościł u nas i brał udział w wycinaniu najdłuższej deski świata, również będącej atrakcją CEPR" actions={[<>Zobacz&nbsp;galerię</>, <>Rezerwuj</>]} />
                      <ListItem title="Apartament Pana Konstantego Kulki" description="polskiego skrzypka, znakomitego wirtuoza i solisty" actions={[<>Zobacz&nbsp;galerię</>, <>Rezerwuj</>]} />
                      <ListItem title="Apartament Pana Leszka Możdżera" description="jako częsty gość grywa na jednej z atrakcji Hotelu, największym fortepianie świata." actions={[<>Zobacz&nbsp;galerię</>, <>Rezerwuj</>]} />
                      <ListItem title="Apartament Pana Lecha Wałęsy" description="były prezydent RP gościł u nas i brał udział w wycinaniu najdłuższej deski świata, również będącej atrakcją CEPR" actions={[<>Zobacz&nbsp;galerię</>, <>Rezerwuj</>]} />
                      <ListItem title="Apartament Pana Konstantego Kulki" description="polskiego skrzypka, znakomitego wirtuoza i solisty" actions={[<>Zobacz&nbsp;galerię</>, <>Rezerwuj</>]} />
                    </ul>
                  </TextBlock>
                </Col>
              </Row>
              <Row className="other-info">
                <Col size={[4, 5, 12, 12]} offset={[1, 0, 0, 0]}>
                  <TextBlock>
                    <h2 className="title">Dodatkowe informacje:</h2>
                  </TextBlock>
                </Col>
              </Row>
              <Row className="items-list">
                <Col size={[10, 12, 12, 12]} offset={[1, 0, 0, 0]}>
                  <TextBlock>
                    <ul>
                      <ListItem title="Dziecko do lat 3 pobyt bezpłatny, jeżeli śpi na obecnym łóżku." />
                      <ListItem title="Dostawka do każdego z pokoi wiąże się z dopłatą w wysokości 70 zł." />
                      <ListItem title="W przypadku osób przebywających w hotelu dłużej niż dobę pobrana zostanie opłata klimatyczna w wysokości 2,41 zł./os. za każdą rozpoczętą dobę pobytu (płatność wyłącznie gotówką w recepcji hotelu)." />
                    </ul>
                  </TextBlock>
                </Col>
              </Row>
            </CdGrid>
          </Container>
        </section>
      </BisquitFrame>
    </>
  );
};

export default ApartamentySection;
