import React, { useEffect, useState } from "react";
import Container from "../../../../components/codeebo-tools/container/container";
import Col from "../../../../components/codeebo-tools/grid/col";
import Row from "../../../../components/codeebo-tools/grid/row";
import BisquitFrame from "../../../../components/frames/bisquit-frame/bisquit-frame";
import TextBlock from "../../../../components/text-block/text-block";
import "./MenuSection.scss";
import CdGrid from "../../../../components/codeebo-tools/grid/grid";
import ListItem from "../../../../components/ListItem/ListItem";
import { menuItemsRestaurant, menuItemsWedding } from "./MenuItems";

interface MenuSectionProps {}

const MenuSection: React.FC<MenuSectionProps> = (props) => {
  const [activePremenu, setActivePremenu] = useState("restauracja");
  const [activeMenu, setActiveMenu] = useState(0);

  useEffect(() => {
    setActiveMenu(0);
  }, [activePremenu]);

  return (
    <>
      <BisquitFrame padding={96} className="HotelFrame">
        <section className="MenuSection">
          <Container>
            <CdGrid>
              <Row className="food-menu">
                <Col size={[9, 10, 12, 12]} offset={[1, 0, 0, 0]}>
                  <TextBlock className="pre-menu">
                    <div className="pre-menu-item">
                      <div className={`pretitle ${activePremenu === "restauracja" ? "active" : ""}`} onClick={() => setActivePremenu("restauracja")}>
                        Menu&nbsp;Restauracyjne
                      </div>
                    </div>
                    <div className="pre-menu-item right">
                      <div className={`pretitle ${activePremenu === "weselne" ? "active" : ""}`} onClick={() => setActivePremenu("weselne")}>
                        Menu&nbsp;Weselne
                      </div>
                    </div>
                  </TextBlock>
                </Col>
                <Col size={[9, 12, 12, 12]} offset={[1, 0, 0, 0]}>
                  <TextBlock className="main-menu">
                    {(activePremenu === "restauracja" ? menuItemsRestaurant : menuItemsWedding).map((item, index) => {
                      return (
                        <div key={index} className={`subtitle ${activeMenu === index ? "active" : ""}`} onClick={() => setActiveMenu(index)}>
                          {item.card}
                        </div>
                      );
                    })}
                  </TextBlock>
                </Col>
              </Row>
              <Row className="items-list">
                <Col size={[9, 12, 12, 12]} offset={[1, 0, 0, 0]}>
                  <TextBlock>
                    <ul>
                      {(activePremenu === "restauracja" ? menuItemsRestaurant : menuItemsWedding)[activeMenu].items.map((item, index) => {
                        return <ListItem key={index} title={item.title} description={item.description} actions={[<>{item.price}</>]} />;
                      })}
                    </ul>
                  </TextBlock>
                </Col>
              </Row>
            </CdGrid>
          </Container>
        </section>
      </BisquitFrame>
    </>
  );
};

export default MenuSection;
