import React from "react";
import Container from "../../../../components/codeebo-tools/container/container";
import Col from "../../../../components/codeebo-tools/grid/col";
import CdGrid from "../../../../components/codeebo-tools/grid/grid";
import Row from "../../../../components/codeebo-tools/grid/row";
import BisquitFrame from "../../../../components/frames/bisquit-frame/bisquit-frame";
import TextBlock from "../../../../components/text-block/text-block";
import TextScroller from "../../../../components/TextScroller/TextScroller";
import "./SuperTitlesSection.scss";

interface SuperTitlesSectionProps {}

const SuperTitlesSection: React.FC<SuperTitlesSectionProps> = (props) => {
  return (
    <BisquitFrame padding={96} className="HotelFrame">
      <section className="SuperTitlesSection">
        <TextBlock className="section-superposttitles-wrapper">
          <CdGrid>
            <Row className="superposttitle first">
              <Col size={[11, 12, 12, 12]} offset={[1, 0, 0, 0]}>
                <Container>
                  <h2>
                    <TextScroller text="Zielone szyszki chmielu," delay={0} />
                  </h2>
                </Container>
              </Col>
            </Row>
            <Row className="superposttitle white">
              <Col size={[10, 10, 12, 12]} offset={[2, 1, 0, 0]}>
                <Container>
                  <h2>
                    <TextScroller text="dzika róża, miód," delay={400} initKey={2} />
                  </h2>
                </Container>
              </Col>
            </Row>
            <Row className="superposttitle">
              <Col size={[11, 12, 12, 12]} offset={[1, 0, 0, 0]}>
                <Container>
                  <h2>
                    <TextScroller text="lecznicze zioła" delay={800} />
                  </h2>
                </Container>
              </Col>
            </Row>
          </CdGrid>
        </TextBlock>
        <CdGrid>
          <Container>
            <Row className="content">
              <Col size={[4, 6, 12, 12]} offset={[1, 0]} className="mt-64">
                <TextBlock className="mt-64">
                  <p> to na pozór proste składniki, jednak za każdym z nich kryje się niesamowita historia. Właściwości lecznicze natury, odpoczynek i piękno znamy już od wieków, dlatego w SPA przygotowaliśmy dla Państwa zabiegi </p>
                </TextBlock>
              </Col>
              <Col size={[4, 6, 12, 12]} offset={[1, 0]} className="mt-64">
                <TextBlock className="mt-64">
                  <p>oparte na tradycji wykorzystywania darów kaszubskiej przyrody. Na każdego czekamy z uśmiechem i otwartymi ramionami, jak na prawdziwych Kaszubów przystało.</p>
                </TextBlock>
              </Col>
            </Row>
          </Container>
        </CdGrid>
      </section>
    </BisquitFrame>
  );
};

export default SuperTitlesSection;
