import { Link } from "react-router-dom";

const PolicyPage: React.FC = () => {
  return (
    <div className="policy-page">
      <h1>Policy page</h1>
      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolore ratione eligendi nostrum fugit rerum impedit quidem esse illum reprehenderit, dignissimos molestias sed! Repudiandae repellendus autem fugiat iure deleniti cum? Magnam.</p>
      <Link to="/">Home</Link>
    </div>
  );
};

export default PolicyPage;
