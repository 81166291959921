import React from "react";
import Col from "../../../components/codeebo-tools/grid/col";
import CdGrid from "../../../components/codeebo-tools/grid/grid";
import Row from "../../../components/codeebo-tools/grid/row";
import TextBlock from "../../../components/text-block/text-block";
import "./MenuOverlayFooter.scss";

interface MenuOverlayFooterProps {}

const MenuOverlayFooter: React.FC<MenuOverlayFooterProps> = (props) => {
  return (
    <CdGrid>
      <Row className="MenuOverlayFooter">
        <Col size={3} offset={1} className="prefooter-block">
          <TextBlock>
            <p className="montserrat">
              Szymbarskich Zakładników 12,
              <br /> 83-315 Szymbark
              <br />
              <a href={`https://www.google.com/maps?ll=54.206185,18.108084&z=16&t=m&hl=pl&gl=PL&mapclient=embed&q=Szymbarskich+Zak%C5%82adnik%C3%B3w+12+83-323+Szymbark`}>Znajdź na GoogleMaps</a>
            </p>
          </TextBlock>
        </Col>

        <Col size={4} className="prefooter-block">
          <TextBlock>
            <p className="montserrat align-center">
              <a href="tel:+48587366240" className="no-decoration">
                +48 58 736-62-40
              </a>
              <br />
              <a href="tel:+48609350488" className="no-decoration">
                +48 609-350-488
              </a>
              <br />
              <a href="mailto:recepcja@hotelszymbark.pl" className="no-decoration">
                recepcja@hotelszymbark.pl
              </a>
            </p>
          </TextBlock>
        </Col>

        <Col size={3} className="prefooter-block">
          <TextBlock>
            <p className="montserrat align-right">
              <a href="https://facebook.com">Facebook</a> <br />
              <a href="https://instagram.com">Instagram</a> <br />
              <a href="https://youtube.com">YouTube</a>
            </p>
          </TextBlock>
        </Col>
      </Row>
    </CdGrid>
  );
};

export default MenuOverlayFooter;
