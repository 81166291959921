export function fetchHomeData(lang = 'pl') {
  return new Promise<{ data: any }>((resolve) => {
    var fields = [
      "populate[Hero_Section][populate]=*",
      "populate[Into_Sections][populate]=*",
      "populate[offers][populate]=*",

      "populate[Atractions][populate]=*",
      "populate[Atractions][sort]=PriorityOrder:DESC", // sort
      "populate[EventsSpaRestaurant_Section][populate]=*",
      "populate[Offers_Section][populate]=*",
      "populate[news][populate]=*",
      "populate[news][sort]=PriorityOrder:DESC", // sort

      "populate[InstagramSection_Gallery][populate]=*",

    ]


    fetch('http://localhost:1337/api/home-page?' + fields.join("&") + "&locale=" + lang, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response =>
        response.text()
      )
      .then(text => {
        return text.replaceAll("/uploads/", "http://localhost:1337/uploads/");
      }
      )
      .then(data => {
        resolve(JSON.parse(data))
      })
      .catch(err => {
        console.log(err)
      })
  });
}
