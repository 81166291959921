import React, { useEffect, useState } from "react";
import Container from "../../../../components/codeebo-tools/container/container";
import Col from "../../../../components/codeebo-tools/grid/col";
import CdGrid from "../../../../components/codeebo-tools/grid/grid";
import Row from "../../../../components/codeebo-tools/grid/row";
import BisquitFrame from "../../../../components/frames/bisquit-frame/bisquit-frame";
import TextBlock from "../../../../components/text-block/text-block";
import "./HeroSection.scss";

import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
import { useAppSelector } from "../../../../store";
import { selectHomeData } from "../../homeDataSlice";
import LinkButtonsRepeater from "../../../../components/LinkButtonsRepeater/LinkButtonsRepeater";

interface HeroSectionProps {}

const HeroSection: React.FC<HeroSectionProps> = (props) => {
  const homeData = useAppSelector(selectHomeData);
  const [Hero_Section, setHero_Section] = useState<any>(null);

  useEffect(() => {
    setHero_Section(homeData?.attributes?.Hero_Section);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [homeData]);

  return (
    <BisquitFrame padding={256} className="home-page">
      <section className="HeroSection">
        <Container>
          <CdGrid>
            <Row>
              <Col size={[3, 4, 12, 12]} offset={[1, 0, 0, 0]} className="content">
                <TextBlock>
                  <div className="pretitle">{Hero_Section?.Subtitle}</div>
                  <h1 className="supertitle">{Hero_Section?.Title}</h1>
                  <p>{Hero_Section?.Description}</p>
                  <LinkButtonsRepeater actionButtonsList={Hero_Section?.ActionButton} />
                </TextBlock>
              </Col>
              <Col size={[7, 7, 12, 12]} className="slider">
                <Swiper modules={[Pagination]} pagination={{ clickable: true }} spaceBetween={100} slidesPerView={1}>
                  {Hero_Section?.Slider?.data?.map((x: any, index: number) => {
                    return (
                      <SwiperSlide key={index}>
                        <img src={x?.attributes?.url} alt={x?.attributes?.alternativeText} />
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </Col>
            </Row>
          </CdGrid>
        </Container>
      </section>
    </BisquitFrame>
  );
};

export default HeroSection;
