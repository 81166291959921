import "./three-stars.scss";
import StarImg from "../../assets/star.svg";

interface Props {
  className?: string;
}

const ThreeStars: React.FC<Props> = (props: Props) => {
  const { className = "" } = props;

  return (
    <div className={`three-stars-wrapper ${className}`}>
      <div className="three-stars">
        <img src={StarImg} alt="star" />
        <img src={StarImg} alt="star" />
        <img src={StarImg} alt="star" />
      </div>
    </div>
  );
};

export default ThreeStars;
