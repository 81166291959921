import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Container from "../../../../components/codeebo-tools/container/container";
import Col from "../../../../components/codeebo-tools/grid/col";
import CdGrid from "../../../../components/codeebo-tools/grid/grid";
import Row from "../../../../components/codeebo-tools/grid/row";
import BisquitFrame from "../../../../components/frames/bisquit-frame/bisquit-frame";
import TextBlock from "../../../../components/text-block/text-block";
import "./OffersSection.scss";
import { useAppSelector } from "../../../../store";
import { selectHomeData } from "../../homeDataSlice";

interface OffersSectionProps {}

const OffersSection: React.FC<OffersSectionProps> = (props) => {
  const homeData = useAppSelector(selectHomeData);
  const [data, set_data] = useState<any>(null);
  const [offers, set_offers] = useState<any>(null);

  useEffect(() => {
    var tempData = homeData?.attributes?.Offers_Section;
    if (tempData) set_data(tempData);

    var tempOffers = homeData?.attributes?.offers;
    if (tempOffers) set_offers(tempOffers);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [homeData]);

  return (
    <BisquitFrame padding={128}>
      <section className="OffersSection">
        <Container>
          <CdGrid>
            <Row>
              <Col size={[10, 12, 12, 12]} offset={[1, 0, 0, 0]}>
                <TextBlock>
                  <h2 className="title">{data?.Title}</h2>
                </TextBlock>
              </Col>
            </Row>
            <Row className="section-description">
              <Col size={[6, 7, 8, 12]} offset={[1, 0, 0, 0]}>
                <TextBlock>
                  <p>{data?.Description}</p>
                </TextBlock>
              </Col>
              <Col size={[3, 4, 4, 12]} offset={[1, 0, 0, 0]}>
                <TextBlock>
                  <p className="montserrat align-right">
                    {data?.Link?.ExternalLink ? <a href={data?.Link?.Link}>{data?.Link?.Title}&nbsp;&gt;</a> : null}
                    {data?.Link?.ExternalLink === false ? <Link to={data?.Link?.Link}>{data?.Link?.Title}&nbsp;&gt;</Link> : null}
                  </p>
                </TextBlock>
              </Col>
            </Row>
            <Row>
              <Col size={[10, 11, 12, 12]} offset={[1, 0, 0, 0]}>
                <Row className="offer-items">
                  {offers?.data?.map((x: any, index: number) => {
                    return (
                      <Col size={[4, 4, 4, 12]} className="offer-item" key={index}>
                        <img src={x.attributes?.Picture?.data?.attributes?.url} alt={x.attributes?.Picture?.data?.attributes?.alternativeText ?? x.attributes?.Title} />
                        <TextBlock>
                          <h3 className="subtitle">{x.attributes?.Title}</h3>
                          <p className="montserrat">{x.attributes?.Price}</p>
                        </TextBlock>
                      </Col>
                    );
                  })}
                </Row>
              </Col>
            </Row>
          </CdGrid>
        </Container>
      </section>
    </BisquitFrame>
  );
};

export default OffersSection;
