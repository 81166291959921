import ExampleWhiteCImg from "./../../../../assets/example-white-c.png";

export const AtrakcjeData = [
  {
    title: "Statua Świętowita Kaszubskiego",
    description: "„opowiada” piękną historię o naszej tradycji, dziedzictwie, miłości do przyrody i dumie z bycia Kaszubami. Statua ma postać odwróconego drzewa symbolizując tym samym ważną rolę jaką w życiu człowieka odgrywa las. W naszym Centrum postać Świętowida ma podkreślić najważniejsze wartości, dzięki którym Kaszubi przetrwali próby dziejowe:",
    image: ExampleWhiteCImg,
    listItems: [
      {
        title: "Religijność",
        desciption: "którą symbolizuje postać pierwszego kaszubskiego biskupa w odrodzonej Polsce - Konstantyna Dominika. Wiara dla Kaszubów miała zawsze ogromne znaczenie i stanowiła o ich sile i jedności.",
      },
      {
        title: "Pracowitość",
        desciption: "Kaszubi od wieków zajmowali się rybactwem, gospodarką leśną i uprawą roli, stąd na naszym drzewie widoczna jest również sieć do połowu ryb, topór i pług.",
      },
      {
        title: "Konsekwencja",
        desciption: "te cechy Kaszubów są ściśle związane z pracowitością i tradycją, a na naszym drzewie uosabia je postać wielkiego Kaszuba - Antoniego Abrahama - propagatora polskości i Pomorza.",
      },
      {
        title: "Patriotyzm",
        desciption: "cechował Polaków od zawsze. Opowiada o nim tekst Mazurka Dąbrowskiego, a na naszym drzewie nawiązuje do niego postać Józefa Wybickiego, autora naszego hymnu narodowego, urodzonego w Będominie, wsi oddalonej od naszego Centrum o ok. 14 km.",
      },
    ],
  },

  {
    title: "Dom Harcerza",
    description: "Obiekt ten został przewieziony z ośrodka wypoczynkowego w Wieżycy. Dom początkowo był przeznaczony do rozbiórki, jednak udało się nam uratować ponad 700 jego elementów, które zrekonstruowaliśmy i uzupełniliśmy, tak aby dom stanął w całej swojej okazałości, tym razem na terenie naszego obiektu. Dom Harcerza jest dla nas symbolem pięknej historii harcerstwa polskiego.",
    image: ExampleWhiteCImg,
  },

  {
    title: "Najdłuższa deska świata",
    description: "na terenie naszego Centrum możecie Państwo zobaczyć dwie deski, którymi wpisaliśmy się do księgi rekordów Guinnessa:",
    image: ExampleWhiteCImg,
    listItems: [
      {
        title: "1.",
        desciption: "Pierwszą deską wyciętą z Daglezji pobiliśmy austriacki rekord o 3 metry. Nasza deska liczyła 36 m i 83 cm i miała 6-7 cm grubości. Jej waga przekraczała 1.100 kg (1,42 m3). Deskę cięło przez 9 dni kilkaset osób, co zintegrowało całą społeczność kaszubską i stało się podstawą do założenia Lokalnej Organizacji Turystycznej Szczyt Wieżyca.",
      },
      {
        title: "2.",
        desciption: "2 czerwca 2012 roku Centrum Edukacji i Promocji Regionu ustanowiło nowy rekord Guinnessa w kategorii Najdłuższa Deska Świata. Cięcie deski rozpoczęła rodzina Państwa Czapiewskich, natomiast Prezydent Lech Wałęsa wraz z małżonką, dokonali ostatniego, symbolicznego cięcia. Dzięki ciężkiej pracy wielu ludzi udało się nam uzyskać zdumiewający wynik - 46,53 metra! Znaczenie rekordu wielokrotnie podkreślał Pan Daniel Czapiewski - właściciel firmy „DANMAR” i inicjator całego przedsięwzięcia. Pierwsza rekordowa deska miała na celu zjednoczyć społeczność kaszubską, obecna ma również zwrócić uwagę na problemy repatriacji i losy ludzi, którzy zostali przymusowo wywiezieni poza granice ojczyzny.",
      },
    ],
  },

  {
    title: "Stół Noblisty",
    description: "Długi na 35 metrów stół, który może pomieścić 230 osoby i waży 6 ton, wykonany został z korony drzewa powstałego podczas cięcia najdłuższej deski świata na pamiątkę Pokojowej Nagrody Nobla przyznanej Lechowi Wałęsie. Nazwaliśmy go Stołem Noblisty. Dzięki niemu nasza deska nie jest już samotnym eksponatem, lecz miejscem, gdzie gromadzą się ludzie.",
    image: ExampleWhiteCImg,
  },
];
