import BisquitFrame from "../../components/frames/bisquit-frame/bisquit-frame";
import TextBlock from "../../components/text-block/text-block";
import ThreeStars from "../../components/three-stars/three-stars";
import "./prefooter.scss";
import LogoImg from "../../assets/logo.svg";
import Container from "../../components/codeebo-tools/container/container";
import Col from "../../components/codeebo-tools/grid/col";
import CdGrid from "../../components/codeebo-tools/grid/grid";
import Row from "../../components/codeebo-tools/grid/row";

const Prefooter: React.FC = () => {
  return (
    <div className="prefooter">
      <BisquitFrame padding={32}>
        <section className="prefooter-section">
          <Container>
            <CdGrid>
              <Row>
                <Col size={[3, 4, 6, 12]} offset={[1, 0, 0, 0]} className="prefooter-block">
                  <TextBlock>
                    <h3 className="subtitle">
                      <ThreeStars />
                      Hotel <br />
                      Szymbark
                    </h3>
                    <p className="montserrat">
                      Szymbarskich Zakładników 12,
                      <br /> 83-315 Szymbark
                      <br />
                      <a href={`https://www.google.com/maps?ll=54.206185,18.108084&z=16&t=m&hl=pl&gl=PL&mapclient=embed&q=Szymbarskich+Zak%C5%82adnik%C3%B3w+12+83-323+Szymbark`}>Znajdź na GoogleMaps</a>
                    </p>
                  </TextBlock>
                </Col>

                <Col size={[3, 3, 6, 12]} offset={[1, 0, 0, 0]} className="prefooter-block">
                  <TextBlock>
                    <h3 className="subtitle">
                      Recepcja <br />
                      i&nbsp;informacja
                    </h3>
                    <p className="montserrat">
                      <a href="tel:+48587366240" className="no-decoration">
                        +48 58 736-62-40
                      </a>
                      <br />
                      <a href="tel:+48609350488" className="no-decoration">
                        +48 609-350-488
                      </a>
                      <br />
                      <a href="mailto:recepcja@hotelszymbark.pl" className="no-decoration">
                        recepcja@hotelszymbark.pl
                      </a>
                    </p>
                  </TextBlock>
                </Col>

                <Col size={[3, 3, 6, 12]} className="prefooter-block">
                  <TextBlock>
                    <h3 className="subtitle">
                      Manager <br />
                      Hotelu
                    </h3>
                    <p className="montserrat">
                      Monika Ittrich <br />
                      <a href="tel:+48725994001" className="no-decoration">
                        +48 725-994-001
                      </a>
                      <br />
                      <a href="mailto:manager@hotelszymbark.pl" className="no-decoration">
                        manager@hotelszymbark.pl
                      </a>
                    </p>
                  </TextBlock>
                </Col>
                <Col size={[3, 4, 0, 0]} offset={[1, 0, 0, 0]} className="prefooter-block">
                  <div className="logo">
                    <img src={LogoImg} alt="logo" />
                  </div>
                </Col>

                <Col size={[3, 3, 6, 12]} offset={[1, 0, 0, 0]} className="prefooter-block">
                  <TextBlock>
                    <h3 className="subtitle">
                      Organizacja <br /> imprez
                    </h3>

                    <p className="montserrat">
                      Elżbieta Jakusz
                      <br />
                      <a href="tel:+48607610317" className="no-decoration">
                        +48 607-610-317
                      </a>
                      <br />
                      <a href="mailto:elzbieta.jakusz@cepr.pl" className="no-decoration">
                        elzbieta.jakusz@cepr.pl
                      </a>
                    </p>
                  </TextBlock>
                </Col>
                <Col size={[3, 3, 6, 12]} className="prefooter-block">
                  <TextBlock>
                    <h3 className="subtitle">
                      Obserwuj <br /> nas na
                    </h3>
                    <p className="montserrat">
                      <a href="https://facebook.com">Facebook</a> <br />
                      <a href="https://instagram.com">Instagram</a> <br />
                      <a href="https://youtube.com">YouTube</a>
                    </p>
                  </TextBlock>
                </Col>
                <Col size={[0, 0, 6, 12]} className="prefooter-block">
                  <div className="logo">
                    <img src={LogoImg} alt="logo" />
                  </div>
                </Col>
              </Row>
            </CdGrid>
          </Container>
        </section>
      </BisquitFrame>
    </div>
  );
};

export default Prefooter;
