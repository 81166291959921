import "./menu-btn.scss";
import MenuCloseImg from "./menu-close.svg";
import MenuOpenImg from "./menu-open.svg";

import { useAppDispatch, useAppSelector } from "../../store";
import { selectStatus, toggle, close } from "./menuSlice";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import LangSwitch from "../lang-switch/lang-switch";

interface Props {
  className?: string;
}

const MenuBtn: React.FC<Props> = (props: Props) => {
  const { className = "" } = props;
  const status = useAppSelector(selectStatus);
  const dispatch = useAppDispatch();

  const location = useLocation();
  useEffect(() => {
    dispatch(close());
    window.scrollTo(0, 0);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <nav className="menu-btns">
      <LangSwitch />
      <button className={`menu-btn status-${status} ${className}`} tabIndex={1} onClick={() => dispatch(toggle())}>
        <img src={MenuOpenImg} className="open" alt="Otwórz menu" />
        <img src={MenuCloseImg} role="button" className="close" alt="Zamknij menu" />
      </button>
    </nav>
  );
};

export default MenuBtn;
