import "./logo-btn.scss";
import Logo from "./logo.svg";
import { Link, useLocation } from "react-router-dom";

interface Props {
  className?: string;
}

const LogoBtn: React.FC<Props> = (props: Props) => {
  const { className = "" } = props;
  const location = useLocation();
  return (
    <>
      {location.pathname === "/" ? null : (
        <Link to={"/"} className={`logo-btn ${className}`} tabIndex={1}>
          <img src={Logo} className="open" alt="Logo" />
        </Link>
      )}
    </>
  );
};

export default LogoBtn;
