import React from "react";
import './Specifications.scss';

interface SpecificationsProps {
  children: React.ReactNode;
}

const Specifications: React.FC<SpecificationsProps> = (props) => {
  const { children } = props;
  
  return <div className="Specifications">{children}</div>;
};

export default Specifications;
