export const menuItemsRestaurant = [
  {
    card: 'Ciasta',
    items: [
      {
        title: 'Pierogi piwne - koźlina - kimchi - śmietana majerankowa',
        description: 'Rekomendowane z: Kartuski Mnich / Wieżyca',
        price: '999 zł'
      },
      {
        title: 'Pierogi piwne - koźlina - kimchi - śmietana majerankowa',
        description: 'Rekomendowane z: Kartuski Mnich / Wieżyca',
        price: '998 zł'
      }
    ]
  },
  {
    card: 'Zupy',
    items: [
      {
        title: 'Pierogi piwne - koźlina - kimchi - śmietana majerankowa',
        description: 'Rekomendowane z: Kartuski Mnich / Wieżyca',
        price: '199 zł'
      },
      {
        title: 'Pierogi piwne - koźlina - kimchi - śmietana majerankowa',
        description: 'Rekomendowane z: Kartuski Mnich / Wieżyca',
        price: '295 zł'
      },
      {
        title: 'Pierogi piwne - koźlina - kimchi - śmietana majerankowa',
        description: 'Rekomendowane z: Kartuski Mnich / Wieżyca',
        price: '398 zł'
      }
    ]
  },
  {
    card: 'Dania główne',
    items: [
      {
        title: 'Pierogi piwne - koźlina - kimchi - śmietana majerankowa',
        description: 'Rekomendowane z: Kartuski Mnich / Wieżyca',
        price: '929 zł'
      },
      {
        title: 'Pierogi piwne - koźlina - kimchi - śmietana majerankowa',
        description: 'Rekomendowane z: Kartuski Mnich / Wieżyca',
        price: '918 zł'
      },
      {
        title: 'Pierogi piwne - koźlina - kimchi - śmietana majerankowa',
        description: 'Rekomendowane z: Kartuski Mnich / Wieżyca',
        price: '295 zł'
      },
    ]
  },
  {
    card: 'Napoje',
    items: [
      {
        title: 'Pierogi piwne - koźlina - kimchi - śmietana majerankowa',
        description: 'Rekomendowane z: Kartuski Mnich / Wieżyca',
        price: '455 zł'
      },
      {
        title: 'Pierogi piwne - koźlina - kimchi - śmietana majerankowa',
        description: 'Rekomendowane z: Kartuski Mnich / Wieżyca',
        price: '518 zł'
      },
      {
        title: 'Pierogi piwne - koźlina - kimchi - śmietana majerankowa',
        description: 'Rekomendowane z: Kartuski Mnich / Wieżyca',
        price: '618 zł'
      }
    ]
  }
]

export const menuItemsWedding = [
  {
    card: 'Przystawki',
    items: [
      {
        title: 'Pierogi piwne - koźlina - kimchi - śmietana majerankowa',
        description: 'Rekomendowane z: Kartuski Mnich / Wieżyca',
        price: '999 zł'
      },
      {
        title: 'Pierogi piwne - koźlina - kimchi - śmietana majerankowa',
        description: 'Rekomendowane z: Kartuski Mnich / Wieżyca',
        price: '995 zł'
      },
      {
        title: 'Pierogi piwne - koźlina - kimchi - śmietana majerankowa',
        description: 'Rekomendowane z: Kartuski Mnich / Wieżyca',
        price: '998 zł'
      }
    ]
  },
  {
    card: 'Zupy',
    items: [
      {
        title: 'Pierogi piwne - koźlina - kimchi - śmietana majerankowa',
        description: 'Rekomendowane z: Kartuski Mnich / Wieżyca',
        price: '199 zł'
      },
      {
        title: 'Pierogi piwne - koźlina - kimchi - śmietana majerankowa',
        description: 'Rekomendowane z: Kartuski Mnich / Wieżyca',
        price: '295 zł'
      },
      {
        title: 'Pierogi piwne - koźlina - kimchi - śmietana majerankowa',
        description: 'Rekomendowane z: Kartuski Mnich / Wieżyca',
        price: '398 zł'
      }
    ]
  },
  {
    card: 'Dania główne',
    items: [
      {
        title: 'Pierogi piwne - koźlina - kimchi - śmietana majerankowa',
        description: 'Rekomendowane z: Kartuski Mnich / Wieżyca',
        price: '929 zł'
      },
      {
        title: 'Pierogi piwne - koźlina - kimchi - śmietana majerankowa',
        description: 'Rekomendowane z: Kartuski Mnich / Wieżyca',
        price: '918 zł'
      }
    ]
  },
  {
    card: 'Napoje',
    items: [
      {
        title: 'Pierogi piwne - koźlina - kimchi - śmietana majerankowa',
        description: 'Rekomendowane z: Kartuski Mnich / Wieżyca',
        price: '329 zł'
      },
      {
        title: 'Pierogi piwne - koźlina - kimchi - śmietana majerankowa',
        description: 'Rekomendowane z: Kartuski Mnich / Wieżyca',
        price: '455 zł'
      },
      {
        title: 'Pierogi piwne - koźlina - kimchi - śmietana majerankowa',
        description: 'Rekomendowane z: Kartuski Mnich / Wieżyca',
        price: '518 zł'
      },
      {
        title: 'Pierogi piwne - koźlina - kimchi - śmietana majerankowa',
        description: 'Rekomendowane z: Kartuski Mnich / Wieżyca',
        price: '618 zł'
      }
    ]
  }
]