import React from "react";
import Container from "../../../../components/codeebo-tools/container/container";
import Col from "../../../../components/codeebo-tools/grid/col";
import CdGrid from "../../../../components/codeebo-tools/grid/grid";
import Row from "../../../../components/codeebo-tools/grid/row";
import BisquitFrame from "../../../../components/frames/bisquit-frame/bisquit-frame";
import TextBlock from "../../../../components/text-block/text-block";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
import CdSwiperNav from "../../../../components/codeebo-tools/swiper-nav/swiper-nav";
import { useAppSelector } from "../../../../store";
import { selectHomeData } from "../../homeDataSlice";
import "./ObserwujSection.scss";

interface ObserwujSectionProps {}

const ObserwujSection: React.FC<ObserwujSectionProps> = (props) => {
  const homeData = useAppSelector(selectHomeData);

  return (
    <BisquitFrame padding={256}>
      <section className="ObserwujSection">
        <Container>
          <CdGrid>
            <Row>
              <Col size={[10, 10, 10, 12]} offset={[1, 0, 0, 0]}>
                <TextBlock>
                  <h5 className="pretitle">{homeData?.attributes?.InstagramSection_Subtitle}</h5>
                </TextBlock>
              </Col>
              <Col size={[8, 8, 8, 12]} offset={[1, 0, 0, 0]}>
                <TextBlock>
                  <h2 className="title">{homeData?.attributes?.InstagramSection_Title}</h2>
                </TextBlock>
              </Col>
              <Col size={[2, 4, 4, 12]}>
                <CdSwiperNav className="obserwuj" color="bisquit" />
              </Col>
            </Row>
            <Row>
              <Col size={[10, 12, 12, 12]} offset={[1, 0, 0, 0]} className="slider">
                <Swiper
                  modules={[Navigation]}
                  navigation={{ nextEl: ".obserwuj-next", prevEl: ".obserwuj-prev" }}
                  pagination={{ clickable: true }}
                  spaceBetween={16}
                  breakpoints={{
                    0: {
                      slidesPerView: 1,
                    },
                    600: {
                      slidesPerView: 2,
                    },
                    900: {
                      slidesPerView: 3,
                    },
                    1200: {
                      slidesPerView: 4,
                    },
                    1600: {
                      slidesPerView: 5,
                    },
                  }}
                  slidesPerView={5}
                  loop={true}
                >
                  {homeData?.attributes?.InstagramSection_Gallery?.data?.map((x: any, index: number) => {
                    return (
                      <SwiperSlide key={index}>
                        <img src={x.attributes?.url} alt={x.attributes?.alternativeText} />
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </Col>
            </Row>
          </CdGrid>
        </Container>
      </section>
    </BisquitFrame>
  );
};

export default ObserwujSection;
