import React from "react";
import "./swiper-nav.scss";

interface Props {
  className: string;
  color?: "white" | "black" | "bisquit";
  onClickPrev?: React.MouseEventHandler<HTMLDivElement> | undefined;
  onClickNext?: React.MouseEventHandler<HTMLDivElement> | undefined;
}

const CdSwiperNav: React.FC<Props> = (props: Props) => {
  const { className, color = "white", onClickPrev, onClickNext } = props;
  return (
    <div className={`swiper-nav variant-${color} ${className}`}>
      <div className={`nav-prev ${className}-prev`} aria-label="Poprzedni slajd" role="button" onClick={onClickPrev}>
        &lsaquo;
      </div>
      <div className={`nav-next ${className}-next`} aria-label="Następny slajd" role="button" onClick={onClickNext}>
        &rsaquo;
      </div>
    </div>
  );
};

export default CdSwiperNav;
