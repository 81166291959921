import React from "react";
import Container from "../../../../components/codeebo-tools/container/container";
import Col from "../../../../components/codeebo-tools/grid/col";
import Row from "../../../../components/codeebo-tools/grid/row";
import BisquitFrame from "../../../../components/frames/bisquit-frame/bisquit-frame";
import TextBlock from "../../../../components/text-block/text-block";
import "./MnichSection.scss";
import BrowarExampleImg from "../../../../assets/browar-example.png";
import CdGrid from "../../../../components/codeebo-tools/grid/grid";

import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
import SpecificationItem from "../../../../components/Specifications/SpecificationItem";
import Specifications from "../../../../components/Specifications/Specifications";

interface MnichSectionProps {}

const MnichSection: React.FC<MnichSectionProps> = (props) => {
  return (
    <>
      <BisquitFrame padding={128} className="HotelFrame">
        <section className="MnichSection">
          <Container>
            <CdGrid>
              <Row>
                <Col size={[4, 5, 12, 12]} offset={[1, 0, 0, 0]} className="content">
                  <TextBlock>
                    <h5 className="pretitle">Poznaj nasze piwa</h5>
                    <h2 className="title">
                      Kartuski <br />
                      Mnich
                    </h2>
                    <Specifications>
                      <SpecificationItem>
                        <b>PIWO:</b> JASNE PEŁNE
                      </SpecificationItem>
                      <SpecificationItem>
                        <b>STYL:</b> JASNY KOŹLAK
                      </SpecificationItem>
                      <SpecificationItem>
                        <b>ALKOHOL:</b> 7%
                      </SpecificationItem>
                    </Specifications>
                    <p>Piwo o wyraźnym aromacie chmielowym z nutą słodową. Smak typowy dla Lagera z mocną charakterystyczną goryczką. Barwa złocista z naturalnymi pęcherzykami dwutlenku węgla. Niefiltrowane, niepasteryzowane.</p>
                  </TextBlock>
                </Col>
                <Col size={[6, 6, 12, 12]} offset={[1, 1, 0, 0]} className="slider">
                  <Swiper modules={[Pagination]} pagination={{ clickable: true }} spaceBetween={100} slidesPerView={1}>
                    <SwiperSlide>
                      <img src={BrowarExampleImg} alt="Browar Kartuski Mnich" />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img src={BrowarExampleImg} alt="Browar Kartuski Mnich" />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img src={BrowarExampleImg} alt="Browar Kartuski Mnich" />
                    </SwiperSlide>
                  </Swiper>
                </Col>
              </Row>
            </CdGrid>
          </Container>
        </section>
      </BisquitFrame>
    </>
  );
};

export default MnichSection;
