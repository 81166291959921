import React from "react";
import Container from "../../../../components/codeebo-tools/container/container";
import Col from "../../../../components/codeebo-tools/grid/col";
import CdGrid from "../../../../components/codeebo-tools/grid/grid";
import Row from "../../../../components/codeebo-tools/grid/row";
import BisquitFrame from "../../../../components/frames/bisquit-frame/bisquit-frame";
import TextBlock from "../../../../components/text-block/text-block";
import "./HeroSection.scss";

interface HeroSectionProps {}

const HeroSection: React.FC<HeroSectionProps> = (props) => {
  return (
    <BisquitFrame padding={96}>
      <section className="HeroSection">
        <Container>
          <CdGrid>
            <Row className="content">
              <Col size={12} offset={[1, 0]}>
                <TextBlock>
                  <h1 className="supertitle">
                    Pokoje
                    <br />
                    Hotelu Szymbark
                  </h1>
                </TextBlock>
              </Col>
              <Col size={[5, 6, 12, 12]} offset={[1, 0]}>
                <TextBlock>
                  <p>W Hotelu znajduje się 63 wygodne pokoje. Z okien roztacza się widok na zadbany ogród, wysokie drzewa oraz wszystkie atrakcje Centrum Edukacji i Promocji Regionu. Wygodne i komfortowe łóżka zapewnią głęboki sen a poranek powita Państwa śpiewem ptaków.</p>
                </TextBlock>
              </Col>
            </Row>
          </CdGrid>
        </Container>
      </section>
    </BisquitFrame>
  );
};

export default HeroSection;
