import React from "react";
import Container from "../../../../components/codeebo-tools/container/container";
import BisquitFrame from "../../../../components/frames/bisquit-frame/bisquit-frame";
import "./IntroSection.scss";
import CdGrid from "../../../../components/codeebo-tools/grid/grid";
import Col from "../../../../components/codeebo-tools/grid/col";
import Row from "../../../../components/codeebo-tools/grid/row";
import TextBlock from "../../../../components/text-block/text-block";
import { itemsData } from "./itemsData";

interface IntroSectionProps {}

const IntroSection: React.FC<IntroSectionProps> = (props) => {
  return (
    <>
      <BisquitFrame padding={64} className="HotelFrame">
        <section className="IntroSection">
          <Container>
            <CdGrid>
              {itemsData.map((x, index: number) => {
                return (
                  <Row className="content" key={index}>
                    <Col size={[4, 4, 12, 12]} offset={[1, 0, 0, 0]}>
                      <TextBlock className="gen-text">{x.columnA}</TextBlock>
                    </Col>
                    <Col size={[4, 4, 12, 12]} offset={[1, 0, 0, 0]}>
                      <TextBlock className="gen-text">{x.columnB}</TextBlock>
                    </Col>
                  </Row>
                );
              })}
            </CdGrid>
          </Container>
        </section>
      </BisquitFrame>
    </>
  );
};

export default IntroSection;
