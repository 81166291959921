import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { fetchHomeData } from './fetchHomeData';

export interface HomeDataState {
  data: any;
  status: 'idle' | 'loading' | 'failed';
}

const initialState: HomeDataState = {
  data: undefined,
  status: 'idle'
};

export const getDataAsync = createAsyncThunk(
  'homeData/fetch',
  async (lang: string) => {
    const response = await fetchHomeData(lang);
    return response.data;
  }
);

export const homeDataSlice = createSlice({
  name: 'homeData',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDataAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getDataAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.data = action.payload;
      });
  },
});

// eslint-disable-next-line no-empty-pattern
export const {} = homeDataSlice.actions;
export const selectHomeData = (state: RootState) => state.homeData.data;
export const selectHomeDataFetch_Status = (state: RootState) => state.counter.status;

export default homeDataSlice.reducer;