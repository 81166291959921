import React from "react";
import { Link } from "react-router-dom";
import Button from "../button/button";

interface LinkButtonProps {
  x: any;
}

const LinkButton: React.FC<LinkButtonProps> = (props) => {
  const { x } = props;

  if (x.ExternalLink)
    return (
      <a href={x.Link}>
        <Button>{x.Title}</Button>
      </a>
    );
  else
    return (
      <Link to={x.Link}>
        <Button>{x.Title}</Button>
      </Link>
    );
};

export default LinkButton;
