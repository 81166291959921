import "./button.scss";

interface Props {
  children: React.ReactNode;
  className?: string;
  onClick?: any;
}

const Button: React.FC<Props> = (props: Props) => {
  const { children, className = "", onClick = undefined } = props;

  return (
    <button onClick={onClick} className={`button ${className}`}>
      {children}
    </button>
  );
};

export default Button;
