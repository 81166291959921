import MapSection from "../../sections/MapSection/MapSection";
import "./browar.scss";
import LaznieSection from "./sections/LaznieSection/LaznieSection";
import HeroSection from "./sections/HeroSection/HeroSection";
import MnichSection from "./sections/MnichSection/MnichSection";

const BrowarPage: React.FC = () => {
  return (
    <div className="browar-page">
      <HeroSection />
      <MnichSection />
      <LaznieSection />

      <MapSection />
    </div>
  );
};

export default BrowarPage;
