import React from "react";
import Container from "../../../../components/codeebo-tools/container/container";
import Col from "../../../../components/codeebo-tools/grid/col";
import CdGrid from "../../../../components/codeebo-tools/grid/grid";
import Row from "../../../../components/codeebo-tools/grid/row";
import FullWidthFrame from "../../../../components/frames/fullwidth-frame/fullwidth-frame";
import TextBlock from "../../../../components/text-block/text-block";
import "./SzefSection.scss";
import FortepianImg from "../../../../assets/szef_kuchni.jpg";

interface SzefSectionProps {}

const SzefSection: React.FC<SzefSectionProps> = (props) => {
  return (
    <FullWidthFrame img={FortepianImg} py={128} px={0}>
      <section className="SzefSection">
        <Container>
          <CdGrid>
            <Row>
              <Col size={[4, 5, 6, 12]} offset={[2, 0, 0, 0]} className="content">
                <TextBlock variant="white">
                  <h5 className="pretitle">Szef kuchni poleca</h5>
                  <h2 className="title">
                    Jerzy <br /> Szurlej
                  </h2>
                  <p>Nam massa arcu, euismod quis dapibus eu, posuere a ipsum. Donec ullamcorper enim orci, ac venenatis lacus maximus consectetur. </p>
                </TextBlock>
              </Col>
              <Col size={[5, 5, 6, 12]} offset={[1, 1, 0, 0]} className="atractions">
                <ul>
                  <li>Sezonowany stek z rostbefu</li>
                  <li>Słatatka Cesar</li>
                  <li>Chłodnik z sezonowymi kiszonkami</li>
                  <li>Lody piwne na bazie Szymbarskiego Smoka</li>
                </ul>
              </Col>
            </Row>
          </CdGrid>
        </Container>
      </section>
    </FullWidthFrame>
  );
};

export default SzefSection;
