import React, { useEffect, useState } from "react";
import Container from "../../../../components/codeebo-tools/container/container";
import Col from "../../../../components/codeebo-tools/grid/col";
import Row from "../../../../components/codeebo-tools/grid/row";
import BisquitFrame from "../../../../components/frames/bisquit-frame/bisquit-frame";
import TextBlock from "../../../../components/text-block/text-block";
import "./HotelSection.scss";
import CdGrid from "../../../../components/codeebo-tools/grid/grid";
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
import { useAppSelector } from "../../../../store";
import { selectHomeData } from "../../homeDataSlice";
import LinkButtonsRepeater from "../../../../components/LinkButtonsRepeater/LinkButtonsRepeater";

interface HotelSectionProps {}

const HotelSection: React.FC<HotelSectionProps> = (props) => {
  const homeData = useAppSelector(selectHomeData);
  const [hotel_row, set_hotel_row] = useState<any>(null);
  const [cepr_row, set_cepr_row] = useState<any>(null);

  useEffect(() => {
    var tempIntoSections = homeData?.attributes?.Into_Sections;
    if (tempIntoSections && typeof tempIntoSections[0] !== "undefined") set_hotel_row(tempIntoSections[0]);
    if (tempIntoSections && typeof tempIntoSections[1] !== "undefined") set_cepr_row(tempIntoSections[1]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [homeData]);

  return (
    <BisquitFrame padding={256} className="HotelFrame">
      <section className="HotelSection">
        <Container>
          <CdGrid>
            <Row>
              <Col size={[4, 4, 12, 12]} offset={[1, 0, 0, 0]} className="content">
                <TextBlock>
                  <h5 className="pretitle">{hotel_row?.Subtitle}</h5>
                  <h2 className="title">{hotel_row?.Title}</h2>
                  <p>{hotel_row?.Description}</p>
                  <LinkButtonsRepeater actionButtonsList={hotel_row?.ActionButton} />
                </TextBlock>
              </Col>
              <Col size={[5, 6, 12, 12]} offset={[1, 1, 0, 0]} className="slider">
                <Swiper modules={[Pagination]} pagination={{ clickable: true }} spaceBetween={100} slidesPerView={1}>
                  {hotel_row?.Slider?.data?.map((x: any, index: number) => {
                    return (
                      <SwiperSlide key={index}>
                        <img src={x?.attributes?.url} alt={x?.attributes?.alternativeText} />
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </Col>
            </Row>
            <Row className="reversed" align="center">
              <Col size={[5, 5, 12, 12]} offset={[1, 0, 0, 0]} className="slider">
                <Swiper modules={[Pagination]} pagination={{ clickable: true }} spaceBetween={100} slidesPerView={1}>
                  {cepr_row?.Slider?.data?.map((x: any, index: number) => {
                    return (
                      <SwiperSlide key={index}>
                        <img src={x?.attributes?.url} alt={x?.attributes?.alternativeText} />
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </Col>
              <Col size={[4, 5, 12, 12]} offset={[1, 1, 0, 0]} className="content">
                <TextBlock>
                  <h5 className="pretitle">{cepr_row?.Subtitle}</h5>
                  <h2 className="title">{cepr_row?.Title}</h2>
                  <p>{cepr_row?.Description}</p>
                  <LinkButtonsRepeater actionButtonsList={cepr_row?.ActionButton} />
                </TextBlock>
              </Col>
            </Row>
          </CdGrid>
        </Container>
      </section>
    </BisquitFrame>
  );
};

export default HotelSection;
