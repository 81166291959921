import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Container from "../../../../components/codeebo-tools/container/container";
import Col from "../../../../components/codeebo-tools/grid/col";
import CdGrid from "../../../../components/codeebo-tools/grid/grid";
import Row from "../../../../components/codeebo-tools/grid/row";
import SwiperNav from "../../../../components/codeebo-tools/swiper-nav/swiper-nav";
import FullWidthFrame from "../../../../components/frames/fullwidth-frame/fullwidth-frame";
import TextBlock from "../../../../components/text-block/text-block";
import "./AtrakcjeSection.scss";
import { useAppSelector } from "../../../../store";
import { selectHomeData } from "../../homeDataSlice";
import T from "../../../../components/lang-switch/t";

interface AtrakcjeSectionProps {}

const AtrakcjeSection: React.FC<AtrakcjeSectionProps> = (props) => {
  const homeData = useAppSelector(selectHomeData);
  const [data, setData] = useState<any>(null);
  const [activeItem, setActiveItem] = useState<any>(null);
  const [activeIndex, setActiveIndex] = useState<number>(0);

  useEffect(() => {
    var tempData = homeData?.attributes?.Atractions?.data;
    if (tempData) {
      setData(tempData);
      setActiveItem(tempData[activeIndex]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [homeData]);

  useEffect(() => {
    if (data && data.length) {
      setActiveItem(data[activeIndex]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeIndex]);

  return (
    <FullWidthFrame bgColor="#746143" img={activeItem?.attributes.Picture_home_page?.data.attributes.url} py={128} px={0}>
      <section className="AtrakcjeSection">
        <Container>
          <CdGrid>
            <Row>
              <Col size={[4, 5, 6, 12]} offset={[2, 0, 0, 0]} className="content">
                <TextBlock variant="white">
                  <h5 className="pretitle">{T("ATRAKCJE", "ATTRACTIONS")}</h5>
                  <h2 className="title">{activeItem?.attributes.Title}</h2>
                  <p>{activeItem?.attributes.Description_home_page}</p>
                </TextBlock>
                <SwiperNav className="atrakcje-nav" onClickNext={() => setActiveIndex((activeIndex + 1) % data.length)} onClickPrev={() => setActiveIndex(((activeIndex > 0 ? activeIndex : data.length) - 1) % data.length)} />
              </Col>
              <Col size={[5, 5, 6, 12]} offset={[1, 1, 0, 0]} className="atractions">
                <ul>
                  {data
                    ?.filter((x: any) => x.id !== activeItem?.id)
                    .map((x: any, index: number) => {
                      return (
                        <li key={index} onClick={() => setActiveIndex(data.findIndex((y: any) => y.id === x.id))}>
                          {x.attributes.Title}
                        </li>
                      );
                    })}
                  <li>
                    <Link to="cepr">{T("Zobacz wszystkie atrakcje", "Check all attractions")} &gt;</Link>
                  </li>
                </ul>
              </Col>
            </Row>
          </CdGrid>
        </Container>
      </section>
    </FullWidthFrame>
  );
};

export default AtrakcjeSection;
