import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Container from "../../../../components/codeebo-tools/container/container";
import Col from "../../../../components/codeebo-tools/grid/col";
import CdGrid from "../../../../components/codeebo-tools/grid/grid";
import Row from "../../../../components/codeebo-tools/grid/row";
import FullWidthFrame from "../../../../components/frames/fullwidth-frame/fullwidth-frame";
import TextBlock from "../../../../components/text-block/text-block";
import "./NewsSection.scss";
import ForestImg from "../../../../assets/las.jpg";
import { useAppSelector } from "../../../../store";
import { selectHomeData } from "../../homeDataSlice";
import t from "../../../../components/lang-switch/t";

interface NewsSectionProps {}

const NewsSection: React.FC<NewsSectionProps> = (props) => {
  const homeData = useAppSelector(selectHomeData);
  const [data, setData] = useState<any>(null);
  const [activeItem, setActiveItem] = useState<any>(null);
  const [activeIndex, setActiveIndex] = useState<number>(0);

  useEffect(() => {
    var tempData = homeData?.attributes?.news?.data;
    if (tempData) {
      setData(tempData);
      setActiveItem(tempData[activeIndex]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [homeData]);

  useEffect(() => {
    if (data && data.length) {
      setActiveItem(data[activeIndex]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeIndex]);

  return (
    <FullWidthFrame img={ForestImg} py={128}>
      <section className="NewsSection">
        <Container>
          <CdGrid>
            <Row>
              <Col size={[4, 5, 6, 12]} offset={[1, 0]} className="content">
                <TextBlock variant="white">
                  <h5 className="pretitle">{t("AKTUALNOŚCI", "NEWS")}</h5>
                  <h2 className="title">{activeItem?.attributes.Title}</h2>
                  <p>{activeItem?.attributes.Description}</p>
                  <p className="montserrat">
                    {activeItem?.attributes?.Link?.ExternalLink ? <a href={activeItem?.attributes?.Link?.Link}>{activeItem?.attributes?.Link?.Title} &gt;</a> : null}
                    {activeItem?.attributes?.Link?.ExternalLink === false ? <Link to={activeItem?.attributes?.Link?.Link}>{activeItem?.attributes?.Link?.Title} &gt;</Link> : null}
                  </p>
                </TextBlock>
              </Col>
              <Col size={[5, 5, 6, 12]} offset={[1, 1, 0, 0]} className="news">
                <ul>
                  {data
                    ?.filter((x: any) => x.id !== activeItem?.id)
                    .map((x: any, index: number) => {
                      return (
                        <li key={index} onClick={() => setActiveIndex(data.findIndex((y: any) => y.id === x.id))}>
                          {x.attributes.Title}
                        </li>
                      );
                    })}
                </ul>
              </Col>
            </Row>
          </CdGrid>
        </Container>
      </section>
    </FullWidthFrame>
  );
};

export default NewsSection;
