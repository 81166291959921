import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';

export interface MenuState {
  status: 'open' | 'close';
}

const initialState: MenuState = {
  status: 'close',
};

export const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    open: (state) => {
      state.status = 'open';
    },
    close: (state) => {
      state.status = 'close';
    },
    toggle: (state) => {
      state.status = state.status === 'close' ? 'open' : 'close';
    },
  },
});

export const { open, close, toggle } = menuSlice.actions;
export const selectStatus = (state: RootState) => state.menu.status;

export default menuSlice.reducer;