import MapSection from "../../sections/MapSection/MapSection";
import "./hotel.scss";
import ApartamentySection from "./sections/ApartamentySection/ApartamentySection";
import HeroSection from "./sections/HeroSection/HeroSection";
import RezerwujSection from "./sections/RezerwujSection/RezerwujSection";

const HotelPage: React.FC = () => {
  return (
    <div className="hotel-page">
      <HeroSection />
      <RezerwujSection />
      <ApartamentySection />

      <MapSection />
    </div>
  );
};

export default HotelPage;
