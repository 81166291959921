import ExampleWhiteBImg from "./../../../../assets/example-white-b.png";
import ExampleWhiteAImg from "./../../../../assets/example-white-a.png";

export const itemsData = [
  {
    columnA: (
      <>
        <h2 className="title">Holistyczny relaks</h2>
        <p>Hotel Szymbark oferuje kompleksowe podejście do relaksu. Poza masażami, pielęgnacją twarzy i ciała, zapraszamy Państwa do strefy saun oraz strefy wellness gdzie można rozkoszować się kąpielami w suszu piwnym, płatkach kwiatów czy w kozim mleku.</p>
      </>
    ),
    columnB: (
      <>
        <img src={ExampleWhiteBImg} alt="" />
      </>
    ),
  },

  {
    columnA: (
      <>
        <img src={ExampleWhiteBImg} alt="" />
      </>
    ),
    columnB: (
      <>
        <h2 className="title">Łaźnie piwne</h2>
        <p>Susz piwny stosowany do kąpieli składa się z wielu dobroczynnych składników stosowanych od lat w ziołolecznictwie i medycynie naturalnej takich jak chmiel, słód czy drożdże. Niewątpliwym atutem łaźni jest możliwość napicia się lokalnego piwa płynącego prosto z nalewaków umieszczonych nad wannami.</p>
      </>
    ),
  },

  {
    columnA: (
      <>
        <p>To unikatowe miejsce stworzone z myślą o Państwa wypoczynku i relaksie. Niepowtarzalne zabiegi energetyzujące, pielęgnacyjne i odmładzające wzmacniają nie tylko ciało, ale również ducha. Wśród kojących dźwięków muzyki mają Państwo niezwykłą okazję na odzyskanie sił witalnych. Doskonała propozycja dla osób pragnących relaksu, wyciszenia i poprawy kondycji organizmu.</p>
      </>
    ),
    columnB: (
      <>
        <img src={ExampleWhiteAImg} alt="" />
      </>
    ),
  },
];
