import MapSection from "../../sections/MapSection/MapSection";
import "./spa.scss";
import HeroSection from "./sections/HeroSection/HeroSection";
import MenuSection from "./sections/MenuSection/MenuSection";
import IntroSection from "./sections/IntroSection/IntroSection";
import SuperTitlesSection from "./sections/SuperTitlesSection/SuperTitlesSection";

const SpaPage: React.FC = () => {
  return (
    <div className="spa-page">
      <HeroSection />
      <SuperTitlesSection />
      <MenuSection />
      <IntroSection />
      <MapSection />
    </div>
  );
};

export default SpaPage;
