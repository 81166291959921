import React from "react";
import Button from "../../../../components/button/button";
import Container from "../../../../components/codeebo-tools/container/container";
import Col from "../../../../components/codeebo-tools/grid/col";
import Row from "../../../../components/codeebo-tools/grid/row";
import BisquitFrame from "../../../../components/frames/bisquit-frame/bisquit-frame";
import TextBlock from "../../../../components/text-block/text-block";
import "./RezerwujSection.scss";
import HotelImg from "../../../../assets/w-sercu-kaszub.jpg";
import CdGrid from "../../../../components/codeebo-tools/grid/grid";

import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";

interface RezerwujSectionProps {}

const RezerwujSection: React.FC<RezerwujSectionProps> = (props) => {
  return (
    <>
      <BisquitFrame padding={64} className="HotelFrame">
        <section className="RezerwujSection">
          <Container>
            <CdGrid>
              <Row>
                <Col size={[4, 5, 12, 12]} offset={[1, 0, 0, 0]} className="content">
                  <TextBlock>
                    <h5 className="pretitle">OD 200 ZŁ</h5>
                    <h2 className="title">
                      Pokój <br /> jednoosobowy
                    </h2>
                    <p>Pokój przeznaczony dla jednej osoby. Część pokoi posiada widok na przepiękne Centrum Edukacji i Promocji Regionu. Pokój wyposażony w funkcjonalne meble oraz telewizor. W pokojach znajduje się łazienka z kabiną prysznicową.</p>
                    <Button>REZERWUJ</Button>
                  </TextBlock>
                </Col>
                <Col size={[6, 6, 12, 12]} offset={[1, 1, 0, 0]} className="slider">
                  <Swiper modules={[Pagination]} pagination={{ clickable: true }} spaceBetween={100} slidesPerView={1}>
                    <SwiperSlide>
                      <img src={HotelImg} alt="Hotel Szymbark" />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img src={HotelImg} alt="Hotel Szymbark" />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img src={HotelImg} alt="Hotel Szymbark" />
                    </SwiperSlide>
                  </Swiper>
                </Col>
              </Row>
            </CdGrid>
          </Container>
        </section>
      </BisquitFrame>
      <BisquitFrame padding={64} className="HotelFrame">
        <section className="RezerwujSection last-section">
          <Container>
            <CdGrid>
              <Row>
                <Col size={[4, 5, 12, 12]} offset={[1, 0, 0, 0]} className="content">
                  <TextBlock>
                    <h5 className="pretitle">OD 250 ZŁ</h5>
                    <h2 className="title">
                      Pokój <br /> dwuosobowy
                    </h2>
                    <p>Pokój przeznaczony dla dwóch osób. Część pokoi posiada widok na przepiękne Centrum Edukacji i Promocji Regionu. Pokój wyposażony w funkcjonalne meble oraz telewizor. W pokojach znajduje się łazienka z kabiną prysznicową.</p>
                    <Button>REZERWUJ</Button>
                  </TextBlock>
                </Col>
                <Col size={[6, 6, 12, 12]} offset={[1, 1, 0, 0]} className="slider">
                  <Swiper modules={[Pagination]} pagination={{ clickable: true }} spaceBetween={100} slidesPerView={1}>
                    <SwiperSlide>
                      <img src={HotelImg} alt="Hotel Szymbark" />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img src={HotelImg} alt="Hotel Szymbark" />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img src={HotelImg} alt="Hotel Szymbark" />
                    </SwiperSlide>
                  </Swiper>
                </Col>
              </Row>
            </CdGrid>
          </Container>
        </section>
      </BisquitFrame>
    </>
  );
};

export default RezerwujSection;
