import React from "react";
import Container from "../../../../components/codeebo-tools/container/container";
import Col from "../../../../components/codeebo-tools/grid/col";
import CdGrid from "../../../../components/codeebo-tools/grid/grid";
import Row from "../../../../components/codeebo-tools/grid/row";
import BisquitFrame from "../../../../components/frames/bisquit-frame/bisquit-frame";
import TextBlock from "../../../../components/text-block/text-block";
import "./HeroSection.scss";
import ExampleWhiteAImg from "./../../../../assets/example-white-a.png";
import ExampleWhiteDImg from "./../../../../assets/example-white-d.png";

interface HeroSectionProps {}

const HeroSection: React.FC<HeroSectionProps> = (props) => {
  return (
    <BisquitFrame padding={96}>
      <section className="HeroSection">
        <Container>
          <CdGrid>
            <Row className="content">
              <Col size={12} offset={[1, 0]}>
                <TextBlock>
                  <h1 className="supertitle">
                    Restauracja
                    <br />
                    Hotelu Szymbark
                  </h1>
                </TextBlock>
              </Col>
              <Col size={[5, 6, 12, 12]} offset={[1, 0]}>
                <TextBlock>
                  <p>Restauracja znakomicie wkomponowuje się w stylistykę naszego obiektu i kusi Gości wspaniałymi, swojskimi aromatami. </p>
                </TextBlock>
              </Col>
            </Row>
            <Row className="gallery">
              <Col size={[4, 6, 12, 12]} offset={[1, 0]}>
                <img className="offset-top" src={ExampleWhiteAImg} alt="example img" />
              </Col>
              <Col size={[4, 6, 12, 12]} offset={[1, 0]}>
                <img src={ExampleWhiteAImg} alt="example img" />
                <img src={ExampleWhiteDImg} alt="example img" />
              </Col>
            </Row>
          </CdGrid>
        </Container>
      </section>
    </BisquitFrame>
  );
};

export default HeroSection;
