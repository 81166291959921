import React from "react";
import Container from "../../../../components/codeebo-tools/container/container";
import Col from "../../../../components/codeebo-tools/grid/col";
import Row from "../../../../components/codeebo-tools/grid/row";
import BisquitFrame from "../../../../components/frames/bisquit-frame/bisquit-frame";
import TextBlock from "../../../../components/text-block/text-block";
import "./DlaczegoSection.scss";
import CdGrid from "../../../../components/codeebo-tools/grid/grid";
import TextScroller from "../../../../components/TextScroller/TextScroller";

interface DlaczegoSectionProps {}

const DlaczegoSection: React.FC<DlaczegoSectionProps> = (props) => {
  return (
    <>
      <BisquitFrame padding={96} className="HotelFrame">
        <section className="DlaczegoSection">
          <TextBlock className="section-superposttitles-wrapper">
            <CdGrid>
              <Row className="superposttitle first">
                <Col size={[11, 12, 12, 12]} offset={[1, 0, 0, 0]}>
                  <Container>
                    <h2>
                      <TextScroller text="Dlaczego" noReverse />
                    </h2>
                  </Container>
                </Col>
              </Row>
              <Row className="superposttitle white">
                <Col size={[10, 10, 12, 12]} offset={[2, 1, 0, 0]}>
                  <Container>
                    <h2>
                      <TextScroller text="Kaszuby?" noReverse delay={500}/>
                    </h2>
                  </Container>
                </Col>
              </Row>
            </CdGrid>
          </TextBlock>
          <Container>
            <CdGrid>
              <Row className="mt-64">
                <Col size={[4, 5, 6, 12]} offset={[1, 0, 0, 0]}>
                  <TextBlock>
                    <p>Nam massa arcu, euismod quis dapibus eu, posuere a ipsum. Donec ullamcorper enim orci, ac venenatis lacus maximus consectetur. Aenean sollicitudin enim neque, ac commodo dui volutpat a. Etiam ut nunc blandit, lacinia purus vitae.</p>
                  </TextBlock>
                </Col>
                <Col size={[4, 5, 6, 12]} offset={[1, 0, 0, 0]}>
                  <TextBlock className="mt-64">
                    <p>Nam massa arcu, euismod quis dapibus eu, posuere a ipsum. Donec ullamcorper enim orci, ac venenatis lacus maximus consectetur. Aenean sollicitudin enim neque, ac commodo dui volutpat a. Etiam ut nunc blandit, lacinia purus vitae.</p>
                  </TextBlock>
                </Col>
              </Row>
            </CdGrid>
          </Container>
        </section>
      </BisquitFrame>
    </>
  );
};

export default DlaczegoSection;
