import React, { useEffect, useState } from "react";
import Container from "../../../../components/codeebo-tools/container/container";
import Col from "../../../../components/codeebo-tools/grid/col";
import CdGrid from "../../../../components/codeebo-tools/grid/grid";
import Row from "../../../../components/codeebo-tools/grid/row";
import BisquitFrame from "../../../../components/frames/bisquit-frame/bisquit-frame";
import TextBlock from "../../../../components/text-block/text-block";
import "./BrowarSection.scss";
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
import { useAppSelector } from "../../../../store";
import { selectHomeData } from "../../homeDataSlice";
import LinkButtonsRepeater from "../../../../components/LinkButtonsRepeater/LinkButtonsRepeater";

interface BrowarSectionProps {}

const BrowarSection: React.FC<BrowarSectionProps> = (props) => {
  const homeData = useAppSelector(selectHomeData);
  const [browar_row, set_browar_row] = useState<any>(null);

  useEffect(() => {
    var tempIntoSections = homeData?.attributes?.Into_Sections;
    if (tempIntoSections && typeof tempIntoSections[2] !== "undefined") set_browar_row(tempIntoSections[2]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [homeData]);

  return (
    <BisquitFrame padding={256}>
      <section className="BrowarSection">
        <Container>
          <CdGrid>
            <Row align="center">
              <Col size={[4, 5, 5, 12]} offset={[1, 0, 0, 0]} className="content">
                <TextBlock>
                  <h5 className="pretitle">{browar_row?.Subtitle}</h5>
                  <h2 className="title">{browar_row?.Title}</h2>
                  <p>{browar_row?.Description}</p>
                  <div className="flex">
                    <LinkButtonsRepeater actionButtonsList={browar_row?.ActionButton} />
                  </div>
                </TextBlock>
              </Col>
              <Col size={[5, 5, 7, 12]} offset={[1, 1, 0, 0]} className="slider">
                <Swiper modules={[Pagination]} pagination={{ clickable: true }} spaceBetween={100} slidesPerView={1}>
                  {browar_row?.Slider?.data?.map((x: any, index: number) => {
                    return (
                      <SwiperSlide key={index}>
                        <img src={x?.attributes?.url} alt={x?.attributes?.alternativeText} />
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </Col>
            </Row>
          </CdGrid>
        </Container>
      </section>
    </BisquitFrame>
  );
};

export default BrowarSection;
