import { Link } from "react-router-dom";
import Button from "../../components/button/button";
import Container from "../../components/codeebo-tools/container/container";
import Col from "../../components/codeebo-tools/grid/col";
import Row from "../../components/codeebo-tools/grid/row";
import t from "../../components/lang-switch/t";
import { selectStatus } from "../../components/nav-buttons/menuSlice";
import TextBlock from "../../components/text-block/text-block";
import ThreeStars from "../../components/three-stars/three-stars";
import { useAppSelector } from "../../store";
import "./menu-overlay.scss";
import MenuOverlayFooter from "./MenuOverlayFooter/MenuOverlayFooter";

interface Props {
  className?: string;
}

const MenuOverlay: React.FC<Props> = (props: Props) => {
  const { className = "" } = props;
  const status = useAppSelector(selectStatus);

  return (
    <div className={`menu-overlay status-${status} ${className}`}>
      <Container className={status === "close" ? "constainer isHidden" : "container"}>
        <Row align={"center"}>
          <Col size={[3, 3, 3, 12]} offset={[1, 1, 0, 0]}>
            <TextBlock>
              <h3 className="subtitle">
                <ThreeStars />
                Hotel <br />
                Szymbark
              </h3>
            </TextBlock>
          </Col>
          <Col size={[4, 4, 6, 12]} className="justify-center rezerwuj">
            <Button>{t("REZERWUJ", "BOOK")}</Button>
          </Col>
        </Row>
        <Row>
          <Col size={[10, 10, 12, 12]} offset={[1, 1, 0, 0]}>
            <nav>
              <ul>
                <li>
                  <Link to={"hotel"}>HOTEL</Link>
                </li>
                <li>
                  <Link to={"cepr"}>CEPR</Link>
                </li>
                <li>
                  <Link to={"browar"}>{t("BROWAR", "BREWERY")}</Link>
                </li>
                <li>
                  <Link to={"restauracja"}>{t("RESTAURACJA", "RESTAURANT")}</Link>
                </li>
                <li>
                  <Link to={"spa"}>SPA</Link>
                </li>
                <li>
                  <Link to={"eventy"}>{t("EVENTY", "EVENTS")} </Link>
                </li>
                <li>
                  <Link to={"/"}>{t("VOUCHERY I BILETY", "VOUCHERS & TICKETS")}</Link>
                </li>
                <li>
                  <Link to={"kontakt"}>{t("KONTAKT", "CONTACT")}</Link>
                </li>
              </ul>
            </nav>
          </Col>
        </Row>
        <MenuOverlayFooter />
      </Container>
    </div>
  );
};

export default MenuOverlay;
