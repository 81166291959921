import React from "react";
import './Specifications.scss';

interface SpecificationItemProps {
  children: React.ReactNode;
}

const SpecificationItem: React.FC<SpecificationItemProps> = (props) => {
  const { children } = props;
  
  return <div className="SpecificationItem">{children}</div>;
};

export default SpecificationItem;
