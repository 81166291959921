import React from "react";
import Container from "../../../../components/codeebo-tools/container/container";
import Col from "../../../../components/codeebo-tools/grid/col";
import Row from "../../../../components/codeebo-tools/grid/row";
import BisquitFrame from "../../../../components/frames/bisquit-frame/bisquit-frame";
import TextBlock from "../../../../components/text-block/text-block";
import "./LaznieSection.scss";
import CdGrid from "../../../../components/codeebo-tools/grid/grid";
import ExampleWhiteAImg from "./../../../../assets/example-white-a.png";
import ExampleWhiteCImg from "./../../../../assets/example-white-c.png";

interface LaznieSectionProps {}

const LaznieSection: React.FC<LaznieSectionProps> = (props) => {
  return (
    <>
      <BisquitFrame padding={96} className="HotelFrame">
        <section className="LaznieSection">
          <Container>
            <CdGrid>
              <Row>
                <Col size={[12, 12, 12, 12]} offset={[1, 0, 0, 0]}>
                  <TextBlock>
                    <h5 className="pretitle">Zrelaksuj się</h5>
                    <h2 className="title">Łaźnie piwne</h2>
                  </TextBlock>
                </Col>
              </Row>
              <Row className="mt-64">
                <Col size={[4, 5, 12, 12]} offset={[1, 1, 0, 0]}>
                  <TextBlock>
                    <p>To unikatowe miejsce stworzone z myślą o Państwa wypoczynku i relaksie. Niepowtarzalne zabiegi energetyzujące, pielęgnacyjne i odmładzające wzmacniają nie tylko ciało, ale również ducha. Wśród kojących dźwięków muzyki mają Państwo niezwykłą okazję na odzyskanie sił witalnych. Doskonała propozycja dla osób pragnących relaksu, wyciszenia i poprawy kondycji organizmu</p>
                  </TextBlock>
                </Col>
                <Col size={[4, 5, 12, 12]} offset={[1, 1, 0, 0]}>
                  <TextBlock>
                    <p>Susz piwny stosowany do kąpieli składa się z wielu dobroczynnych składników stosowanych od lat w ziołolecznictwie i medycynie naturalnej takich jak chmiel, słód czy drożdże. Niewątpliwym atutem łaźni jest możliwość napicia się lokalnego piwa płynącego prosto z nalewaków umieszczonych nad wannami.</p>
                  </TextBlock>
                </Col>
              </Row>
              <Row className="mt-128">
                <Col size={[4, 6, 12, 12]} offset={[1, 0]}>
                  <img className="offset-top" src={ExampleWhiteAImg} alt="example img" />
                  <TextBlock className="mt-48">
                    <p>Nam massa arcu, euismod quis dapibus eu, posuere a ipsum. Donec ullamcorper enim orci, ac venenatis lacus maximus consectetur. </p>
                  </TextBlock>
                  <img className="offset-top mt-64" src={ExampleWhiteAImg} alt="example img" />
                  <TextBlock className="mt-48">
                    <p>Nam massa arcu, euismod quis dapibus eu, posuere a ipsum. Donec ullamcorper enim orci, ac venenatis lacus maximus consectetur. </p>
                  </TextBlock>
                </Col>
                <Col size={[4, 6, 12, 12]} offset={[1, 0]} className="mt-64">
                  <img className="mt-64" src={ExampleWhiteAImg} alt="example img" />
                  <img className="mt-64" src={ExampleWhiteCImg} alt="example img" />
                  <TextBlock className="mt-64">
                    <p>Nam massa arcu, euismod quis dapibus eu, posuere a ipsum.</p>
                  </TextBlock>
                </Col>
              </Row>
            </CdGrid>
          </Container>
        </section>
      </BisquitFrame>
    </>
  );
};

export default LaznieSection;
