import MapSection from "../../sections/MapSection/MapSection";
import "./eventy.scss";
import HeroSection from "./sections/HeroSection/HeroSection";
import AtrakcjeSection from "./sections/EventySection/EventySection";

const EventyPage: React.FC = () => {
  return (
    <div className="eventy-page">
      <HeroSection />
      <AtrakcjeSection />
      <MapSection />
    </div>
  );
};

export default EventyPage;
