import React, { useState } from "react";
import Container from "../../../../components/codeebo-tools/container/container";
import Col from "../../../../components/codeebo-tools/grid/col";
import Row from "../../../../components/codeebo-tools/grid/row";
import BisquitFrame from "../../../../components/frames/bisquit-frame/bisquit-frame";
import TextBlock from "../../../../components/text-block/text-block";
import "./AtrakcjeSection.scss";
import CdGrid from "../../../../components/codeebo-tools/grid/grid";
import ListItem from "../../../../components/ListItem/ListItem";
import { AtrakcjeData } from "./AtrakcjeData";
import Button from "../../../../components/button/button";

interface AtrakcjeSectionProps {}

const AtrakcjeSection: React.FC<AtrakcjeSectionProps> = (props) => {
  const [activeAtractions, setActiveAtractions] = useState(2);

  const loadMore = () => {
    setActiveAtractions(activeAtractions + 1);
  };

  return (
    <>
      {AtrakcjeData.slice(0, activeAtractions).map((x, index) => {
        return (
          <BisquitFrame key={index} padding={128} className="HotelFrame">
            <section className="AtrakcjeSection">
              <Container>
                <CdGrid>
                  <Row className="description-row">
                    <Col size={[4, 5, 12, 12]} offset={[1, 0, 0, 0]} className="section-content">
                      <TextBlock>
                        <h2 className="title">{x.title}</h2>
                        <p className="description">{x.description}</p>
                      </TextBlock>
                    </Col>
                    <Col size={[5, 6, 12, 12]} offset={[1, 1, 0, 0]} className="img">
                      <img src={x.image} alt="Statua Świętowita Kaszubskiego" />
                    </Col>
                  </Row>
                  {x.listItems ? (
                    <Row>
                      <Col size={[10, 12, 12, 12]} offset={[1, 0, 0, 0]} className="options">
                        <TextBlock>
                          <ul>
                            {x.listItems?.map((item, itemIndex) => {
                              return <ListItem key={itemIndex} title={item.title} description={item.desciption} />;
                            })}
                          </ul>
                        </TextBlock>
                      </Col>
                    </Row>
                  ) : null}
                </CdGrid>
              </Container>
            </section>
          </BisquitFrame>
        );
      })}
      {activeAtractions >= AtrakcjeData.length ? null : (
        <BisquitFrame padding={32} className="HotelFrame loadMore">
          <section className="AtrakcjeSection">
            <Container>
              <CdGrid>
                <Row>
                  <Col size={[7, 8, 10, 12]} offset={[2, 2, 1, 0]} className="loadMore">
                    <Button onClick={() => loadMore()}>+ Pokaż jeszcze więcej atrakcji</Button>
                  </Col>
                </Row>
              </CdGrid>
            </Container>
          </section>
        </BisquitFrame>
      )}
    </>
  );
};

export default AtrakcjeSection;
