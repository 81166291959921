import React, { useState } from "react";
import Container from "../../../../components/codeebo-tools/container/container";
import Col from "../../../../components/codeebo-tools/grid/col";
import Row from "../../../../components/codeebo-tools/grid/row";
import BisquitFrame from "../../../../components/frames/bisquit-frame/bisquit-frame";
import TextBlock from "../../../../components/text-block/text-block";
import "./EventySection.scss";
import CdGrid from "../../../../components/codeebo-tools/grid/grid";
import ListItem from "../../../../components/ListItem/ListItem";
import { EventyData } from "./EventyData";
import Button from "../../../../components/button/button";
// import ReactMarkdown from "react-markdown";

interface EventySectionProps {}

const EventySection: React.FC<EventySectionProps> = (props) => {
  const [activeEvents, setActiveEvents] = useState(3);

  const loadMore = () => {
    setActiveEvents(activeEvents + 1);
  };

  return (
    <>
      {EventyData.slice(0, activeEvents).map((x, index) => {
        return (
          <>
            {x.fullWidthImg ? <div className="fullWidthImg" style={{ backgroundImage: `url(${x.fullWidthImg})` }} /> : null}
            <BisquitFrame key={index} padding={128} className="HotelFrame">
              <section className="EventySection">
                <Container>
                  <CdGrid>
                    <Row className="description-row section-content">
                      <Col size={[4, 5, 12, 12]} offset={[1, 0, 0, 0]}>
                        <TextBlock>
                          {x.title ? <h2 className="title">{x.title}</h2> : null}
                          <div className="description">
                            {x.columnA}
                            {/* <ReactMarkdown>{x.columnA}</ReactMarkdown> */}
                          </div>
                        </TextBlock>
                      </Col>
                      <Col size={[5, 6, 12, 12]} offset={[1, 1, 0, 0]}>
                        <TextBlock>
                          <div className="description">
                            {x.columnB}
                            {/* <ReactMarkdown>{x.columnB}</ReactMarkdown> */}
                          </div>
                        </TextBlock>
                      </Col>
                    </Row>
                    {x.listItems ? (
                      <Row>
                        {x.listItemsTitle ? (
                          <Col size={[4, 5, 12, 12]} offset={[1, 0, 0, 0]}>
                            <TextBlock className="mt-64">
                              <p className="description">{x.listItemsTitle}</p>
                            </TextBlock>
                          </Col>
                        ) : null}
                        <Col size={[10, 12, 12, 12]} offset={[1, 0, 0, 0]} className="options">
                          <TextBlock>
                            <ul>
                              {x.listItems?.map((item: any, itemIndex: number) => {
                                return <ListItem key={itemIndex} title={item.title} description={item.desciption} />;
                              })}
                            </ul>
                          </TextBlock>
                        </Col>
                      </Row>
                    ) : null}
                  </CdGrid>
                </Container>
              </section>
            </BisquitFrame>
          </>
        );
      })}
      {activeEvents >= EventyData.length ? null : (
        <BisquitFrame padding={32} className="HotelFrame loadMore">
          <section className="EventySection">
            <Container>
              <CdGrid>
                <Row>
                  <Col size={[7, 8, 10, 12]} offset={[2, 2, 1, 0]} className="loadMore">
                    <Button onClick={() => loadMore()}>+ Pokaż jeszcze więcej eventów</Button>
                  </Col>
                </Row>
              </CdGrid>
            </Container>
          </section>
        </BisquitFrame>
      )}
    </>
  );
};

export default EventySection;
