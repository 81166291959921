import { useState } from "react";
import { useSpring, animated } from "react-spring";

const TextScroller = ({ text, delay, initKey, noReverse }: any) => {
  const [key, setKey] = useState(initKey ?? 1);

  const scrolling = useSpring({
    from: { transform: "translate(110%,0)" },
    to: { transform: "translate(-110%,0)" },
    delay: delay ?? 0,
    config: { duration: 8000 + (delay ?? 0) },
    reset: true,
    reverse: key % 2 === 0 && !noReverse,
    onRest: () => {
      setKey(key + 1);
    },
  });

  return (
    <div key={key}>
      <animated.div style={scrolling}>{text}</animated.div>
    </div>
  );
};

export default TextScroller;
