import React from "react";
import Container from "../../../../components/codeebo-tools/container/container";
import Col from "../../../../components/codeebo-tools/grid/col";
import Row from "../../../../components/codeebo-tools/grid/row";
import BisquitFrame from "../../../../components/frames/bisquit-frame/bisquit-frame";
import TextBlock from "../../../../components/text-block/text-block";
import "./WPoblizuSection.scss";
import CdGrid from "../../../../components/codeebo-tools/grid/grid";
import { WPoblizuData } from "./WPoblizuData";
import ToggleWPoblizuItem from "./ToggleWPoblizuItem/ToggleWPoblizuItem";

interface WPoblizuSectionProps {}

const WPoblizuSection: React.FC<WPoblizuSectionProps> = (props) => {
  return (
    <>
      <BisquitFrame padding={128} className="HotelFrame">
        <section className="WPoblizuSection">
          <Container>
            <CdGrid>
              <Row>
                <Col size={[6, 10, 12, 12]} offset={[1, 0]}>
                  <TextBlock>
                    <h2 className="title">Atrakcje turystyczne w pobliżu Hotelu Szymbark</h2>
                  </TextBlock>
                </Col>
              </Row>
              <Row>
                <Col size={[10, 12, 12, 12]} offset={[1, 0, 0, 0]} className="atrakcje-w-poblizu">
                  <TextBlock>
                    {WPoblizuData.map((item, itemIndex) => {
                      return (
                        <ToggleWPoblizuItem key={itemIndex} title={item.title} distance={item.distance} address={item.address} website={item.website}>
                          {item.description}
                        </ToggleWPoblizuItem>
                      );
                    })}
                  </TextBlock>
                </Col>
              </Row>
            </CdGrid>
          </Container>
        </section>
      </BisquitFrame>
    </>
  );
};

export default WPoblizuSection;
