import Container from "../../components/codeebo-tools/container/container";
import FullWidthFrame from "../../components/frames/fullwidth-frame/fullwidth-frame";
import Col from "../../components/codeebo-tools/grid/col";
import CdGrid from "../../components/codeebo-tools/grid/grid";
import Row from "../../components/codeebo-tools/grid/row";
import TextBlock from "../../components/text-block/text-block";
import "./footer.scss";

const Footer: React.FC = () => {
  return (
    <footer className="footer">
      <FullWidthFrame>
        <Container>
          <CdGrid>
            <Row>
              <Col size={[6, 6, 6, 12]}>
                <TextBlock>
                  <p className="montserrat small">Copyrights © Hotel Szymbark 2021</p>
                </TextBlock>
              </Col>
              <Col size={[6, 6, 6, 12]}>
                <TextBlock>
                  <p className="montserrat align-right small">
                    Projekt i realizacja: <a href="https://spnm.pl">SPNM</a> &amp; <a href="https://codeebo.pl">Codeebo</a>
                  </p>
                </TextBlock>
              </Col>
            </Row>
          </CdGrid>
        </Container>
      </FullWidthFrame>
    </footer>
  );
};

export default Footer;
