import "./bisquit-frame.scss";

interface Props {
  children?: React.ReactNode;
  className?: string;
  padding?: 256 | 192 | 128 | 96 | 64 | 32;
  color?: string;
}

const BisquitFrame: React.FC<Props> = (props: Props) => {
  const { children, className = "", padding = "0", color } = props;

  return (
    <div className={`bisquit-frame ${"padding-" + padding} ${className}`} style={{ backgroundColor: color }}>
      {children}
    </div>
  );
};

export default BisquitFrame;
