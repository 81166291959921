export const WPoblizuData = [
  {
    title: "Kaszubska wieża widokowa na wzgórzu Wieżyca – 330 m n.p.m.",
    description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eligendi, iste molestiae? Dolores, natus! Ea reiciendis vitae cum hic, corrupti laborum. Tempora tempore aut, exercitationem dolore reprehenderit est nesciunt eius laborum.",
    distance: 2.2,
    address: "Lorem ipsum 34/6, 83-333 Chmielno",
    website: "www.example.pl",
  },
  {
    title: "Koszałkowo – Wieżyca Centrum Aktywnego Wypoczynku",
    description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eligendi, iste molestiae? Dolores, natus! Ea reiciendis vitae cum hic, corrupti laborum. Tempora tempore aut, exercitationem dolore reprehenderit est nesciunt eius laborum.",
    distance: 5.5,
    address: "Lorem ipsum 34/6, 83-333 Chmielno",
    website: "www.example.pl",
  },
  {
    title: "Pracownia ceramiki kaszubskiej w Chmielnie",
    description: "Poznajcie historię 10 pokoleń rodzinnej pracowni garncarskiej Państwa Neclów. Sztukę prawdziwego kaszubskiego rzemieślnictwa możecie obejrzeć właśnie w Chmielnie. W pracowni czeka na Was wystawa ceramiki kaszubskiej oraz, co wyjątkowe w tego typu miejscach, możliwość wzięcia udziału w warsztatach garncarskich. Każdy chętny może pod okiem wykwalifikowanego garncarza stworzyć swoje własne naczynie gliniane, nadać mu wybrany kolor oraz zlecić jego wypalenie w specjalnym piecu.",
    distance: 20,
    address: "Gryfa Pomorskiego 65, 83-333 Chmielno",
    website: "www.necel.pl",
  },
];
