import { Route, Routes } from "react-router-dom";
import "./App.scss";
import MenuBtn from "./components/nav-buttons/menu-btn";
import PixelPerfect from "./components/codeebo-tools/pixel-perfect/pixel-perfect";
import StickyBookingBtn from "./components/sticky-booking-btn/sticky-booking-btn";
import HomePage from "./pages/home/home";
import PolicyPage from "./pages/policy/policy";
import Footer from "./sections/footer/footer";
import MenuOverlay from "./sections/menu-overlay/menu-overlay";
import Prefooter from "./sections/prefooter/prefooter";
import LogoBtn from "./components/logo-btn/logo-btn";
import HotelPage from "./pages/hotel/hotel";
import RestauracjaPage from "./pages/restauracja/restauracja";
import BrowarPage from "./pages/browar/browar";
import "./global_scss/swiper/_swiper-pagination.scss";
import CeprPage from "./pages/cepr/cepr";
import AosInit from "./components/codeebo-tools/aos-init/aos-init";
import EventyPage from "./pages/eventy/eventy";
import SpaPage from "./pages/spa/spa";
import ContactPage from "./pages/contact/contact";

function App() {
  return (
    <div className="App">
      <AosInit />
      <LogoBtn />
      <MenuBtn />
      <MenuOverlay />
      <StickyBookingBtn />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="hotel" element={<HotelPage />} />
        <Route path="browar" element={<BrowarPage />} />
        <Route path="restauracja" element={<RestauracjaPage />} />
        <Route path="cepr" element={<CeprPage />} />
        <Route path="eventy" element={<EventyPage />} />
        <Route path="spa" element={<SpaPage />} />
        <Route path="kontakt" element={<ContactPage />} />
        <Route path="policy" element={<PolicyPage />} />
      </Routes>
      <Prefooter />
      <Footer />
    </div>
  );
}

export default App;
