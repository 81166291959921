import "./fullwidth-frame.scss";

interface Props {
  children: React.ReactNode;
  className?: string;
  img?: string;
  bgColor?: string;
  px?: 128 | 96 | 64 | 32 | 0;
  py?: 128 | 96 | 64 | 32 | 0;
}

const FullWidthFrame: React.FC<Props> = (props: Props) => {
  const { children, className = "", px = "0", py = "0", img, bgColor } = props;

  return (
    <div className={`fullwidth-frame ${"px-" + px} ${"py-" + py} ${className}`} style={{ backgroundColor: bgColor, backgroundImage: `url(${img})` }}>
      {children}
    </div>
  );
};

export default FullWidthFrame;
