import React from "react";
import Container from "../../../../components/codeebo-tools/container/container";
import Col from "../../../../components/codeebo-tools/grid/col";
import CdGrid from "../../../../components/codeebo-tools/grid/grid";
import Row from "../../../../components/codeebo-tools/grid/row";
import BisquitFrame from "../../../../components/frames/bisquit-frame/bisquit-frame";
import TextBlock from "../../../../components/text-block/text-block";
import "./HeroSection.scss";

interface HeroSectionProps {}

const HeroSection: React.FC<HeroSectionProps> = (props) => {
  return (
    <BisquitFrame padding={96}>
      <section className="HeroSection">
        <Container>
          <CdGrid>
            <Row className="content">
              <Col size={12} offset={[1, 0]}>
                <TextBlock>
                  <h1 className="supertitle">
                    SPA <br /> Hotelu Szymbark
                  </h1>
                </TextBlock>
              </Col>
              <Col size={[5, 6, 12, 12]} offset={[1, 0]}>
                <TextBlock>
                  <p>Kaszuby to kraina niezwykła. Obok pięknej przyrody i darów natury znajdziesz tu życzliwych i uśmiechniętych ludzi. W naszym SPA łączymy to wszystko. </p>
                </TextBlock>
              </Col>
            </Row>
          </CdGrid>
        </Container>
      </section>
    </BisquitFrame>
  );
};

export default HeroSection;
