import MapSection from "../../sections/MapSection/MapSection";
import "./cepr.scss";
import HeroSection from "./sections/HeroSection/HeroSection";
import DlaczegoSection from "./sections/DlaczegoSection/DlaczegoSection";
import AtrakcjeSection from "./sections/AtrakcjeSection/AtrakcjeSection";
import WPoblizuSection from "./sections/WPoblizuSection/WPoblizuSection";

const CeprPage: React.FC = () => {
  return (
    <div className="cepr-page">
      <HeroSection />
      <DlaczegoSection />
      <AtrakcjeSection />
      <WPoblizuSection />
      <MapSection />
    </div>
  );
};

export default CeprPage;
