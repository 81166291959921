import React from "react";
import Container from "../../components/codeebo-tools/container/container";
import Col from "../../components/codeebo-tools/grid/col";
import CdGrid from "../../components/codeebo-tools/grid/grid";
import Row from "../../components/codeebo-tools/grid/row";
import BisquitFrame from "../../components/frames/bisquit-frame/bisquit-frame";
import TextBlock from "../../components/text-block/text-block";
import "./MapSection.scss";

interface MapSectionProps {
}

const MapSection: React.FC<MapSectionProps> = (props) => {

  return (
    <BisquitFrame padding={64}>
      <section className="MapSection">
        <Container>
          <CdGrid>
            <Row>
              <Col size={[10, 12, 12, 12]} offset={[1, 0, 0, 0]}>
                <TextBlock>
                  <h5 className="pretitle">LOKALIZACJA</h5>
                  <h2 className="title">Znajdź nas</h2>
                </TextBlock>
              </Col>
            </Row>
            <Row>
              <Col size={[10, 12, 12, 12]} offset={[1, 0, 0, 0]}>
                <div className="mapmock"></div>
                {/* <iframe id="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2333.5255369815636!2d18.105895715871075!3d54.206184580168205!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46fd86060e0780db%3A0x1b9b96a391b64485!2sSzymbarskich%20Zak%C5%82adnik%C3%B3w%2012%2C%2083-323%20Szymbark!5e0!3m2!1spl!2spl!4v1636385174382!5m2!1spl!2spl" width="100%" loading="lazy" style={{ border: "none" }} title="Szymbarskich Zakładników 12, 83-323 Szymbark"></iframe> */}
              </Col>
            </Row>
          </CdGrid>
        </Container>
      </section>
    </BisquitFrame>
  );
};

export default MapSection;
