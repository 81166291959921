import React from "react";
import Container from "../../../../components/codeebo-tools/container/container";
import Col from "../../../../components/codeebo-tools/grid/col";
import CdGrid from "../../../../components/codeebo-tools/grid/grid";
import Row from "../../../../components/codeebo-tools/grid/row";
import BisquitFrame from "../../../../components/frames/bisquit-frame/bisquit-frame";
import TextBlock from "../../../../components/text-block/text-block";
import "./HeroSection.scss";

interface HeroSectionProps {}

const HeroSection: React.FC<HeroSectionProps> = (props) => {
  return (
    <BisquitFrame padding={96}>
      <section className="HeroSection">
        <Container>
          <CdGrid>
            <Row className="content">
              <Col size={12} offset={[1, 0]}>
                <TextBlock>
                  <h1 className="supertitle">Kontakt</h1>
                </TextBlock>
              </Col>
            </Row>
            <Row>
              <Col size={3} offset={[1, 0]}>
                <TextBlock>
                  <p className="montserrat">
                    <b>Adres</b> <br />
                    Szymbarskich Zakładników 12, <br /> 83-315 Szymbark
                  </p>
                </TextBlock>
              </Col>
              <Col size={3} offset={[1, 0]}>
                <TextBlock>
                  <p className="montserrat">
                    <b>Recepcja</b> <br />
                    +48 58 736-62-40 <br />
                    +48 609-350-488 <br />
                    recepcja@hotelszymbark.pl <br />
                  </p>
                </TextBlock>
              </Col>
              <Col size={3} offset={[1, 0]}>
                <TextBlock>
                  <p className="montserrat">
                    <b>REGON:</b> 220924076 <br />
                    <b>NIP:</b> 5891976164 <br />
                    <b>KRS:</b> 0000345359
                  </p>
                </TextBlock>
              </Col>
            </Row>
          </CdGrid>
        </Container>
      </section>
    </BisquitFrame>
  );
};

export default HeroSection;
