import "./text-block.scss";

interface Props {
  children: React.ReactNode;
  className?: string;
  variant?: "white";
}

const TextBlock: React.FC<Props> = (props: Props) => {
  const { children, className = "", variant = "dark" } = props;

  return <div className={`text-block ${variant} ${className}`}>{children}</div>;
};

export default TextBlock;
