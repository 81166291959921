import MapSection from "../../sections/MapSection/MapSection";
import "./contact.scss";
import ContactFormSection from "./sections/ContactFormSection/ContactFormSection";
import HeroSection from "./sections/HeroSection/HeroSection";

const ContactPage: React.FC = () => {
  return (
    <div className="contact-page">
      <HeroSection />
      <ContactFormSection />
      <MapSection />
    </div>
  );
};

export default ContactPage;
