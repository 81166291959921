import React from "react";
import LinkButton from "./LinkButton";

interface LinkButtonsRepeaterProps {
  actionButtonsList: any;
}

const LinkButtonsRepeater: React.FC<LinkButtonsRepeaterProps> = (props) => {
  const { actionButtonsList } = props;

  return (
    <>
      {actionButtonsList?.map((x: any, index: number) => {
        return <LinkButton x={x} key={index}/>;
      })}
    </>
  );
};

export default LinkButtonsRepeater;
