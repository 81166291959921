import "./home.scss";
import HeroSection from "./sections/HeroSection/HeroSection";
import HotelSection from "./sections/HotelSection/HotelSection";
import AtrakcjeSection from "./sections/AtrakcjeSection/AtrakcjeSection";
import BrowarSection from "./sections/BrowarSection/BrowarSection";
import CategorySection from "./sections/CategorySection/CategorySection";
import OffersSection from "./sections/OffersSection/OffersSection";
import NewsSection from "./sections/NewsSection/NewsSection";
import ObserwujSection from "./sections/ObserwujSection/ObserwujSection";
import MapSection from "../../sections/MapSection/MapSection";
import { useAppDispatch, useAppSelector } from "../../store";
import { selectLang } from "../../components/lang-switch/langSlice";
import { useEffect } from "react";
import { getDataAsync, selectHomeData } from "./homeDataSlice";

const HomePage: React.FC = () => {
  const lang = useAppSelector(selectLang);
  const homeData = useAppSelector(selectHomeData);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getDataAsync(lang));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  useEffect(() => {
    // console.log(homeData);
  }, [homeData]);

  return (
    <div className="home-page">
      <HeroSection />
      <HotelSection />
      <AtrakcjeSection />
      <BrowarSection />
      <CategorySection />
      <OffersSection />
      <NewsSection />
      <ObserwujSection />
      <MapSection />
    </div>
  );
};

export default HomePage;
