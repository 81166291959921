import React, { useState } from "react";
import TextBlock from "../../../../../components/text-block/text-block";
import "./ToggleWPoblizuItem.scss";

interface ToggleWPoblizuItemProps {
  children: React.ReactNode;
  title: string;
  distance: number;
  address: string;
  website: string;
}

const ToggleWPoblizuItem: React.FC<ToggleWPoblizuItemProps> = (props) => {
  const { children, address, website, title, distance } = props;
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="ToggleWPoblizuItem">
      <div className="header" onClick={() => setIsOpen(!isOpen)}>
        <TextBlock>
          <div className="sign">{isOpen ? "-" : "+"}</div>
          <p className="toggle-title">
            <b> {title}</b>
          </p>
          <div className="distance">
            <p className="montserrat right">
              <b>Odległość:</b>
              <span>{distance} km</span>
            </p>
          </div>
        </TextBlock>
      </div>
      {isOpen ? (
        <div className="collapsible">
          <TextBlock>
            <p className="description">{children}</p>
            {address || website ? (
              <div className="other-info">
                {address ? (
                  <p>
                    <b>Adres:</b> {address}
                  </p>
                ) : null}
                {website ? (
                  <p>
                    <b>Strona internetowa:</b>
                    {website}
                  </p>
                ) : null}
              </div>
            ) : null}
          </TextBlock>
        </div>
      ) : null}
    </div>
  );
};

export default ToggleWPoblizuItem;
