import React, { useEffect, useState } from "react";
import Container from "../../../../components/codeebo-tools/container/container";
import Col from "../../../../components/codeebo-tools/grid/col";
import CdGrid from "../../../../components/codeebo-tools/grid/grid";
import Row from "../../../../components/codeebo-tools/grid/row";
import BisquitFrame from "../../../../components/frames/bisquit-frame/bisquit-frame";
import TextBlock from "../../../../components/text-block/text-block";
import "./ContactFormSection.scss";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "../../../../components/button/button";

interface ContactFormSectionProps {}

const ContactFormSection: React.FC<ContactFormSectionProps> = (props) => {
  const [nameSurname, setNameSurname] = useState<any>("");
  const [email, setEmail] = useState<any>("");
  const [phone, setPhone] = useState<any>("");
  const [message, setMessage] = useState<any>("");
  const [category, setCategory] = useState<any>("");
  const [acceptRules, setAcceptRules] = useState(false);

  const [emailMandatory, setEmailMandatory] = useState(true);
  const [rulesMandatory, setRulesMandatory] = useState(true);

  useEffect(() => {
    if (acceptRules) setRulesMandatory(false);
    if (email) setEmailMandatory(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptRules, email]);

  const validate = () => {
    if (!email) setEmailMandatory(true);
    if (!acceptRules) setRulesMandatory(true);

    if (!email || !acceptRules) return false;
    else return true;
  };

  const send = () => {
    if (!validate()) {
      return;
    }

    var payload = {
      nameSurname: nameSurname,
      email: email,
      phone: phone,
      message: message,
      category: category,
      acceptRules: acceptRules,
    };

    alert("MESSAGE HAS BEEN SEND");
    
    console.log("SEND", payload);
  };

  return (
    <>
      <BisquitFrame padding={128} color="#F7E9D5" className="contact-form-frame">
        <section className="ContactFormSection">
          <Container>
            <CdGrid>
              <Row className="content">
                <Col size={[8, 10, 12]} offset={[2, 1, 0]}>
                  <TextBlock>
                    <h3 className="subtitle">Formularz kontaktowy</h3>
                    {/* {message}
                    {phone}
                    {email}
                    {nameSurname}
                    {acceptRules === true ? "true" : "false"} */}
                  </TextBlock>
                </Col>
                <Col size={[4, 5, 6]} offset={[2, 1, 0]}>
                  <TextBlock>
                    <TextField id="name-surname" label="Imię i nazwisko" onChange={(e) => setNameSurname(e.target.value)} />
                    <TextField id="email" label="Adres e-mail*" onChange={(e) => setEmail(e.target.value)} error={emailMandatory} />
                  </TextBlock>
                </Col>
                <Col size={[4, 5, 6]}>
                  <TextBlock>
                    <TextField id="phone" label="Numer telefonu" onChange={(e) => setPhone(e.target.value)} />
                    <FormControl className="">
                      <InputLabel htmlFor="category">Kategoria zapytania</InputLabel>
                      <Select
                        native
                        value={category}
                        onChange={(e) => setCategory(e.target.value)}
                        inputProps={{
                          name: "category",
                          id: "category",
                        }}
                      >
                        <option aria-label="None" value="" />
                        <option value={"ten"}>Ten</option>
                        <option value={"twenty"}>Twenty</option>
                        <option value={"thirty"}>Thirty</option>
                      </Select>
                    </FormControl>
                  </TextBlock>
                </Col>
                <Col size={[8, 10, 12]} offset={[2, 1, 0]}>
                  <TextBlock>
                    <TextField id="message" label="Treść wiadomości" multiline rows={6} onChange={(e) => setMessage(e.target.value)} />
                  </TextBlock>
                </Col>
              </Row>
              <Row className={"align-center accept-and-send"}>
                <Col size={[4, 5, 6]} offset={[2, 1, 0]}>
                  <TextBlock>
                    <FormControlLabel
                      control={<Checkbox checked={acceptRules} onChange={(e) => setAcceptRules(e.target.checked)} name="acceptRules" />}
                      label={
                        <span style={{ color: rulesMandatory ? "#f44336" : "black" }}>
                          Zapoznałam/-em się z <u>Klauzulą informacyjną</u>*
                        </span>
                      }
                    />
                  </TextBlock>
                </Col>
                <Col size={[4, 5, 6]}>
                  <Button onClick={() => send()}>WYŚLIJ WIADOMOŚĆ</Button>
                </Col>
              </Row>
            </CdGrid>
          </Container>
        </section>
      </BisquitFrame>
      <BisquitFrame padding={64} />
    </>
  );
};

export default ContactFormSection;
