export const menuItemsSpa = [
  {
    card: 'Masaże',
    items: [
      {
        title: 'Quisque ornare mi vel nonummy id',
        description: 'Vivamus imperdiet nunc, tempus purus sit amet elit arcu, imperdiet id, adipiscing metus. Etiam ac turpis metus in augue. Maecenas feugiat augue nec velit magna, tincidunt viverra.',
        price: '999 zł'
      },
      {
        title: 'Quisque ornare mi vel nonummy id',
        description: 'Vivamus imperdiet nunc, tempus purus sit amet elit arcu, imperdiet id, adipiscing metus. Etiam ac turpis metus in augue. Maecenas feugiat augue nec velit magna, tincidunt viverra.',
        price: '998 zł'
      },
      {
        title: 'Quisque ornare mi vel nonummy id',
        description: 'Vivamus imperdiet nunc, tempus purus sit amet elit arcu, imperdiet id, adipiscing metus. Etiam ac turpis metus in augue. Maecenas feugiat augue nec velit magna, tincidunt viverra.',
        price: '998 zł'
      }
    ]
  },
  {
    card: 'Lorem',
    items: [
      {
        title: 'Quisque ornare mi vel nonummy id',
        description: 'Vivamus imt id, adipiscing metus. Etiam ac turpis metus in augue. Maecenas feugiat augue nec velitperdiet nunc, tempus purus sit amet elit arcu, imperdie magna, tincidunt viverra.',
        price: '299 zł'
      },
      {
        title: 'Nonummy id quisque ornare mi vel ',
        description: 'Vivamus imper, adipiscing metus. Etiam ac turpis metus in augue. Maecenas feugiat auguediet nunc, tempus purus sit amet elit arcu, imperdiet id nec velit magna, tincidunt viverra.',
        price: '998 zł'
      },
      {
        title: 'Quisque ornare mi vel nonummy id',
        description: 'Maecenas feugiat augue nec velit magna, tincidu. Vivamus imperdiet nunc, tempus purus sit amet elit arcu, imperdiet id, adipiscing metus. Etiam ac turpis metus t viverra.',
        price: '298 zł'
      }
    ]
  },
  {
    card: 'Lorem ipsum',
    items: [
      {
        title: 'Nonummy id quisque ornare mi vel ',
        description: 'Vivamus imper, adipiscing metus. Etiam ac turpis metus in augue. Maecenas feugiat auguediet nunc, tempus purus sit amet elit arcu, imperdiet id nec velit magna, tincidunt viverra.',
        price: '98 zł'
      },
      {
        title: 'Anare mi vel nonu quisque ormmy id',
        description: 'Viv urus sit amet elit arcu, imperdiet id, adipiscing metus. Etiam ac turpis metus in augue. Maecenas feugiat augue nec vamus imperdiet nunc, tempus pelit magna, tincidunt viverra.',
        price: '98 zł'
      },
      {
        title: 'Nonummy id quisque ornare mi vel ',
        description: 'Vivamus imper, adipiscing metus. Etiam ac turpis metus in augue. Maecenas feugiat auguediet nunc, tempus purus sit amet elit arcu, imperdiet id nec velit magna, tincidunt viverra.',
        price: '98 zł'
      },
    ]
  },
  {
    card: 'Dolor',
    items: [
      {
        title: 'Qu orisquee el nor nanumi vmmy id',
        description: 'Vm ac turpiiet nunc, temp ivamus imperdurus sit amet elt id, adipiscing metus. Etiaus ps metus in augue. Maecenas feugiat auguit arcu, imperdiee nec velit magna, tincidunt viverra.',
        price: '199 zł'
      },
      {
        title: 'Qu isqre mi vel nue ornaonummy id',
        description: 'Vivamus impe, imperdiet id, adipiscing metus. Etiam ac turpis metus in augue. Maecenas feugiat augue nec velit magna, tincidunt rdiet nunc, tempus purus sit amet elit arcuviverra.',
        price: '698 zł'
      },
      {
        title: 'Vivamus imperdiet nunci lorem sod',
        description: 'Quisque ornare mi vel nonummy nc, tempus purus sit amet elit arcu, imperdiet id, adipiscing metus. Etiam ac turpis metus in augue. Maecenas feugiat augue nec velit magna, tincidunt viverra.',
        price: '798 zł'
      }
    ]
  }
]